import { useState } from "react";

import Dropzone from "react-dropzone";

import { classNames, convertToDefEventPara } from "../../shared/utility";

export default function ImportMediaDropzone(props) {
  const { fileName, handleInputChange, setUploadedImage } = props;

  const [error, setError] = useState(null);

  return (
    <div className="mt-2">
      <div>
        <Dropzone
          onDrop={(acceptedFile) => {
            if (acceptedFile.length > 0) {
              handleInputChange(convertToDefEventPara(fileName, acceptedFile));

              setUploadedImage(
                acceptedFile.map((file) => URL.createObjectURL(file))
              );
              // setUploadedImage(URL.createObjectURL(acceptedFile));
            } else {
              setError("Could not upload file, please try again.");
            }
          }}
          maxFiles={1}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({})}>
              <button
                type="button"
                className="rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              >
                <input {...getInputProps()} />
                Upload image
              </button>
            </div>
          )}
        </Dropzone>
      </div>
      <div className={classNames(error ? "text-center p-4" : "hidden")}>
        <h3 className="text-lg leading-6 font-normal text-red-500">{error}</h3>
      </div>
    </div>
  );
}
