import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";
import { SimpleNotificationType } from "../../components/SimpleNotification";
import { useForm } from "../../components/forms/useForm";
import { updateObjectInArray } from "../../shared/utility";

import ChangeCustomerForm from "../../components/forms/orders/modals/ChangeCustomerForm";

export function CreateOrderViewModel(
  cartCustomer,
  modifyCart,
  logout,
  setCartCustomer
) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  // const customerCartInitialValues = {
  //   id: 0,
  //   company_name: null,
  //   customer_id: null,
  //   customer_group_id: null,
  // };
  const [customerOptions, setCustomerOptions] = useState(null);
  const [primaryCategories, setPrimaryCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [products, setProducts] = useState(null);
  const [breadCrumbs, setBreadCrumbs] = useState([
    // {
    //   name: "Orders",
    //   href: "/orders",
    //   current: false,
    // },
    // { name: "Create New", href: "/create-order", current: true },
  ]);

  const getCustomersForDropdown = async function (searchVal) {
    setLoading(true);

    let callResult = await makePostApiCall(`getCustomersForDropdown`, {
      search: searchVal,
    });
    setLoading(false);
    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.customer = null;
    if (values.customer === "") {
      temp.customer = "Please fill out field.";
    }

    temp.expected_date = null;
    if (values.expected_date === "") {
      temp.expected_date = "Please fill out field.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    cartCustomer,
    false,
    validate
  );

  const handleSubmit = () => {
    if (values.id > 0) {
      // updateOrder();
    } else {
      createOrderApiCall();
    }
  };

  const onCustomerSelected = (cust) => {
    const selectedCust = {
      ...values,
      company_name: cust.label,
      customer_id: cust.value,
      customer_group_id: cust.group_id,
      city: cust.city,
      line1: cust.line1,
      line2: cust.line2,
      postal_code: cust.postal_code,
      province: cust.province,
      country_code: cust.country_code,
    };
    setValues(selectedCust);
    setCartCustomer(selectedCust);
    getPrimaryCategoriesForCustomerGroup(cust.label, cust.group_id);
  };

  const onCustomerRelatedChange = (name, value) => {
    const selectedCust = {
      ...values,
      [name]: value,
    };
    setValues(selectedCust);
    setCartCustomer(selectedCust);
  };

  const primaryCategorySelected = (cat) => {
    setValues({
      ...values,
      primary_category: cat.id,
    });
    getSubCategoriesOrProductsForPrimaryCategory(cat.name, cat.id);
  };

  const subCategorySelected = (cat) => {
    setValues({
      ...values,
      sub_category: cat.id,
    });
    getProductsForSubCategory(cat.name, cat.id);
  };

  const onProductQuantityChanged = (
    changedQuantity,
    product,
    index,
    isPlus,
    isMinus
  ) => {
    const copyProducts = [...products];
    const singleItem = copyProducts[index];

    var newQuantity = changedQuantity;
    if (isPlus) {
      newQuantity = singleItem.quantity + 1;
    }

    if (isMinus) {
      newQuantity = singleItem.quantity - 1;
    }

    const price = singleItem.group_price
      ? singleItem.group_price
      : singleItem.unit_price;

    const singleItemBeingEdited = {
      ...singleItem,
      quantity: newQuantity,
      price: price,
      discount: singleItem.discount ? singleItem.discount : 0,
      taxes: singleItem.taxes ? singleItem.taxes : 0,
      total: price * newQuantity,
    };
    var payload = {
      index: index,
      item: singleItemBeingEdited,
    };
    const newProducts = updateObjectInArray(copyProducts, payload);
    setProducts(newProducts);
    modifyCart(changedQuantity, singleItemBeingEdited, isPlus, isMinus);
  };

  console.log("VM00-values", values);

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const createOrderApiCall = async function () {
    const payload = { ...values, products: products };
    let callResult = await makePostApiCall(`createOrder`, payload);
    if (callResult.success) {
      window.location = `/orders/${callResult.data.id}`;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const primaryCategoryBreadcrumbClicked = (currBreadcrumbs) => {
    setSubCategories(null);
    setProducts(null);
    setValues({
      ...values,
      primary_category: null,
      sub_category: null,
    });
    let copy = [...currBreadcrumbs];
    let newCopy = copy.slice(0, 1);
    setBreadCrumbs(newCopy);
  };

  const subCategoryBreadcrumbClicked = (currBreadcrumbs) => {
    setProducts(null);
    setValues({
      ...values,
      primary_category: null,
      sub_category: null,
    });
    let copy = [...currBreadcrumbs];
    let newCopy = copy.slice(0, currBreadcrumbs.length - 1);
    setBreadCrumbs(newCopy);
  };

  const productBreadcrumbClicked = (currBreadcrumbs) => {
    /*
     * Since we are at the product, it's the last breadcrumb
     * and we don't do anything
     */
  };

  const getPrimaryCategoriesForCustomerGroup = async function (
    customerGroupName,
    customerGroupId
  ) {
    let callResult = await makePostApiCall(
      `getPrimaryCategoriesForCustomerGroup/${customerGroupId}`
    );
    if (callResult.success) {
      setPrimaryCategories(callResult.data);
      setBreadCrumbs([
        ...breadCrumbs,
        {
          name: "Categories",
          href: "#",
          current: true,
          onClick: primaryCategoryBreadcrumbClicked,
        },
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getSubCategoriesOrProductsForPrimaryCategory = async function (
    catName,
    catId
  ) {
    let callResult = await makePostApiCall(
      `getSubCategoriesOrProductsForPrimaryCategory/${catId}`
    );
    if (callResult.success) {
      const type = callResult.data.type;
      if (type === 1) {
        setSubCategories(callResult.data.items);
        setBreadCrumbs([
          ...breadCrumbs,
          {
            name: catName,
            href: "#",
            current: true,
            onClick: subCategoryBreadcrumbClicked,
          },
        ]);
      } else if (type === 2) {
        setProducts(callResult.data.items);
        setBreadCrumbs([
          ...breadCrumbs,
          {
            name: catName,
            href: "#",
            current: true,
            onClick: productBreadcrumbClicked,
          },
        ]);
      } else {
        showErrorModal("It seems no sub category or product is found.");
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getProductsForSubCategory = async function (catName, catId) {
    let callResult = await makePostApiCall(
      `getProductsForSubCategory/${catId}`
    );
    if (callResult.success) {
      setProducts(callResult.data);
      setBreadCrumbs([
        ...breadCrumbs,
        {
          name: catName,
          href: "#",
          current: true,
          onClick: productBreadcrumbClicked,
        },
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openChangeCustomerModal = () => {
    setEmptyModelOpen({
      childComponent: (
        <ChangeCustomerForm
          setOpen={setEmptyModelOpen}
          getCustomersForDropdown={getCustomersForDropdown}
          onCustomerSelected={onCustomerSelected}
          onCustomerRelatedChange={onCustomerRelatedChange}
          confirmAction={() => {}}
          cartCustomer={cartCustomer}
        />
      ),
      open: true,
    });
  };

  return {
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    createOrderApiCall,
    customerOptions,
    breadCrumbs,
    primaryCategories,
    subCategories,
    products,
    onCustomerSelected,
    primaryCategorySelected,
    subCategorySelected,
    handleSubmit,
    values,
    onProductQuantityChanged,
    handleInputChange,
    errors,
    openChangeCustomerModal,
    getPrimaryCategoriesForCustomerGroup,
  };
}
