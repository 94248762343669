import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";

import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";
import { SimpleNotificationType } from "../../../components/SimpleNotification";

export function CustomerGroupDetailsViewModel(logout, groupId) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState({ customers: [] });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });

  const getGroup = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(
      `customerGroups/${groupId}`,
      payload
    );
    setLoading(false);
    if (callResult.success) {
      setGroup(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };
  const createGroup = async function (values) {
    let callResult = await makePostApiCall(
      `createCustomerGroup`,
      values,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Customer Group created",
        type: SimpleNotificationType.Success,
      });
      window.location =
        "/customer-groups/" + callResult.data.id + "?created-new=true";
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateGroup = async function (values) {
    let callResult = await makePostApiCall(
      `updateCustomerGroups/${groupId}`,
      values,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  // const deleteCustomer = async function (id, index) {
  //   setLoading(true);

  //   let callResult = await makePostApiCall(`deleteCustomer/${id}`, {});
  //   setLoading(false);
  //   if (callResult.success) {
  //     setEmptyModelOpen({
  //       open: false,
  //       childComponent: null,
  //     });
  //     setShowSimpleNotification({
  //       show: true,
  //       title: callResult.data.message,
  //       type: SimpleNotificationType.Success,
  //     });
  //   } else {
  //     if (callResult.errorStatus === 401) {
  //       logout();
  //     }
  //   }
  // };

  // const askDeleteCustomer = function (customer, index) {
  //   setEmptyModelOpen({
  //     childComponent: (
  //       <DeleteConfirmationForm
  //         buttonMessage="Delete"
  //         confirmAction={() => deleteCustomer(customer.id, index)}
  //         message={`Are you sure you want to delete ${customer.name}?`}
  //         setOpen={setEmptyModelOpen}
  //       />
  //     ),
  //     open: true,
  //   });
  // };

  return {
    createGroup,
    updateGroup,
    getGroup,
    showSimpleNotification,
    setShowSimpleNotification,
    group,
    loading,
    emptyModal,
  };
}
