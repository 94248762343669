import React, { useEffect, useRef } from "react";

import { useForm } from "../../useForm";

import Input from "../../../controls/Input";
import { classNames } from "primereact/utils";

const initialFValues = {
  id: 0,
  quantity: null,
};

export default function AddProductToOrderForm(props) {
  const { product, confirmAction, setOpen } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.quantity = null;
    if (values.quantity < 1) {
      temp.quantity = "Order quantity must be more than 0.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const onSubmit = () => {
    if (validate()) {
      confirmAction({
        ...product,
        id: -1,
        quantity: values.quantity,
      });
    } else {
    }
  };

  return (
    <div className="justify-between">
      <div>
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {product.product_name} - ({product.plu})
            </h3>
            <h3 className="text-md leading-6 font-medium text-gray-900">
              {product.description}
            </h3>
          </div>
        </div>

        {/* here */}
        <div className="pt-3 pb-5 gap-4">
          <div className="bg-white sm:rounded-xl">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="flex space-x-2 mb-1">
                  <label
                    htmlFor="quantity"
                    className={classNames(
                      "text-sm",
                      "block font-medium text-gray-900"
                    )}
                  >
                    Amount to order
                  </label>
                </div>
                <input
                  name="quantity"
                  onChange={handleInputChange}
                  value={values.quantity}
                  ref={inputRef}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  onKeyDown={(e) => (e.key === "Enter" ? onSubmit() : null)}
                />
                {errors.quantity ? (
                  <p className="font-medium text-red-500">{errors.quantity}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="sm:flex-no-wrap my-auto flex items-center">
            <button
              onClick={() => onSubmit()}
              className="inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
            >
              OK
            </button>
            <button
              onClick={() => setOpen()}
              className="ml-2 inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
