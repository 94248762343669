import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  cart: [],
  cartCustomer: null,
};

const modifyCart = (state, action) => {
  return updateObject(state, { cart: action.cart });
};

const modifyCartCustomer = (state, action) => {
  return updateObject(state, { cartCustomer: action.cartCustomer });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CART:
      return modifyCart(state, action);
    case actionTypes.SET_CART_CUSTOMER:
      return modifyCartCustomer(state, action);

    default:
      return state;
  }
};

export default reducer;
