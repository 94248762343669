import React, { useEffect, useRef, useState } from "react";

import { useForm } from "../../useForm";

import { classNames } from "primereact/utils";
import Input from "../../../controls/Input";
import SelectMenu from "../../../controls/SelectMenu";
import DatePickerInput from "../../../controls/DatePickerInput";

import AsyncSelect from "react-select/async";

const initialFValues = {
  id: 0,
  quantity: null,
};

export default function ChangeCustomerForm(props) {
  const {
    getCustomersForDropdown,
    confirmAction,
    onCustomerSelected,
    setOpen,
    onCustomerRelatedChange,
    cartCustomer,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  useEffect(() => {
    setValues({ ...initialFValues, ...cartCustomer });
  }, []);

  const onSubmit = () => {
    confirmAction();
  };

  const [asyncSelectValue, setAsyncSelectValue] = useState({
    value: -1,
    label: "Please type 3 or more words",
  });

  const loadOptions = async function (input, callback) {
    if (input.length > 2) {
      const result = await getCustomersForDropdown(input);
      callback(result);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 gap-3">
      <div>
        <div className="flex space-x-2 mb-1">
          <label className={"text-sm block font-medium text-gray-900"}>
            Choose Customer
          </label>
        </div>
        <AsyncSelect
          cacheOptions
          loadOptions={loadOptions}
          options={[]}
          className="w-full"
          onChange={(e) => {
            setAsyncSelectValue(e);
          }}
          value={asyncSelectValue}
        />
      </div>

      <Input
        label="PO #"
        name="po_number"
        onChange={(e) => {
          handleInputChange(e);
          onCustomerRelatedChange("po_number", e.target.value);
        }}
        value={values.po_number}
        labelOn={true}
        type="text"
        error={errors.po_number}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
      />

      <DatePickerInput
        label="Order Date"
        name="order_date"
        onChange={(e) => {
          handleInputChange(e);
          onCustomerRelatedChange("order_date", e.target.value);
        }}
        value={values.order_date}
        labelOn={true}
        minDate={new Date()}
        dateFormat="LLL dd, YYY"
        type="text"
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
        error={errors.order_date}
      />

      <DatePickerInput
        label="Expected Date"
        name="expected_date"
        onChange={(e) => {
          handleInputChange(e);
          onCustomerRelatedChange("expected_date", e.target.value);
        }}
        value={values.expected_date}
        labelOn={true}
        minDate={new Date()}
        dateFormat="LLL dd, YYY"
        type="text"
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
        error={errors.expected_date}
      />
      <div className="sm:flex-no-wrap my-auto flex items-center">
        <button
          onClick={() => {
            if (asyncSelectValue.value > -1) {
              onCustomerSelected(asyncSelectValue);
            }
            setOpen({
              open: false,
            });
          }}
          className="inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
        >
          OK
        </button>
        <button
          onClick={() =>
            setOpen({
              open: false,
            })
          }
          className="ml-2 inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
