import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";

import { SimpleNotificationType } from "../../components/SimpleNotification";

export function SubCategoriesViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [subCategories, setSubCategories] = useState({
    data: [],
  });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getSubCategories = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`products/subcategories`, payload);
    setLoading(false);
    if (callResult.success) {
      setSubCategories(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    emptyModal,
    setEmptyModelOpen,
    getSubCategories,
    loading,
    subCategories,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
  };
}
