import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CustomersViewModel } from "../../viewmodels/customers/CustomersViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { classNames, withRouter } from "../../shared/utility";

import { Link } from "react-router-dom";

import SlideOver from "../../components/slideOver/SlideOver";
import EmptyModal from "../../components/modals/EmptyModal";

import { Paginator } from "primereact/paginator";
import SimpleNotification from "../../components/SimpleNotification";
import CustomersBulkActions from "../../components/forms/customer/modals/CustomersBulkActions";

function Customers(props) {
  const { logout } = props;
  const {
    emptyModal,
    setEmptyModelOpen,
    getCustomers,
    loading,
    customers,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    getCustomerGroupsForDropdown,
    customerGroupOptions,
    onBulkAction,
  } = CustomersViewModel(logout);

  var currentPage = 1;
  useEffect(() => {
    getCustomerGroupsForDropdown();
    getCustomers(null, currentPage);
  }, []);

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(50);

  const searchRef = useRef();

  const handleSearch = () => {
    getCustomers(searchRef.current.value, currentPage);
  };

  const handlePagination = (event) => {
    currentPage = event.page + 1;
    setFirst(event.first);
    setRows(event.rows);
    getCustomers(searchRef.current.value, currentPage);
  };

  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedCustomers.length > 0 &&
      selectedCustomers.length < customers.data.length;
    setChecked(
      selectedCustomers.length === customers.data.length &&
        customers.data.length > 0
    );
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedCustomers]);

  function toggleAll() {
    setSelectedCustomers(checked || indeterminate ? [] : customers.data);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  return (
    <div>
      <div className="mt-2">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center px-4 py-5 sm:px-4 card-custom">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Customers
              </h1>
              <div className="mt-5 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchProduct"
                    id="searchProduct"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by customer name"
                    ref={searchRef}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleSearch();
                    }}
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
                {/* <button
                  type="button"
                  className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => {
                    window.location = "/customers/new";
                  }}
                >
                  Add customer
                </button> */}
                <button
                  className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() =>
                    setEmptyModelOpen({
                      childComponent: (
                        <CustomersBulkActions
                          customerGroupOptions={customerGroupOptions}
                          confirmAction={(bulkActionValues) =>
                            onBulkAction({
                              selectedCustomers: [...selectedCustomers],
                              ...bulkActionValues,
                            })
                          }
                          setOpen={setEmptyModelOpen}
                        />
                      ),
                      open: true,
                    })
                  }
                >
                  Bulk actions
                </button>
              </div>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden card-custom relative">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-white">
                        <tr>
                          <th
                            scope="col"
                            className="relative px-7 sm:w-12 sm:px-6"
                          >
                            <input
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              ref={checkbox}
                              checked={checked}
                              onChange={toggleAll}
                            />
                          </th>
                          <th
                            scope="col"
                            className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Contact
                          </th>{" "}
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Customer group
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Allocated to
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {customers.data.map((customer, index) => (
                          <tr
                            key={customer.email}
                            className={
                              selectedCustomers.includes(customer)
                                ? "bg-gray-50"
                                : undefined
                            }
                          >
                            <td className="relative px-7 sm:w-12 sm:px-6">
                              {selectedCustomers.includes(customer) && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                              )}
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                value={customer.email}
                                checked={selectedCustomers.includes(customer)}
                                onChange={(e) =>
                                  setSelectedCustomers(
                                    e.target.checked
                                      ? [...selectedCustomers, customer]
                                      : selectedCustomers.filter(
                                          (p) => p !== customer
                                        )
                                  )
                                }
                              />
                            </td>
                            <td
                              className={classNames(
                                "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                                selectedCustomers.includes(customer)
                                  ? "text-indigo-600"
                                  : "text-gray-900"
                              )}
                            >
                              {customer.company_name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {customer.given_name} {customer.family_name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {customer.group_names}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {customer.allocated_to}
                            </td>
                            <td className="relative whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <Link
                                className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                to={"/customers/" + customer.id}
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="w-full">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={customers.total}
                  onPageChange={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Customers)
);
