export default function CustomerGroupCustomersTable(props) {
  const { customers } = props;

  return (
    <div className="overflow-hidden card-custom">
      <div className="p-4">
        <p className="text-md font-semibold">Customers in group</p>
      </div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-white">
          <tr>
            <th
              scope="col"
              className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Name
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {customers.map((customer, index) => (
            <tr key={customer.id}>
              <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {customer.company_name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
