import { useEffect, useState } from "react";

export default function OrderSummary(props) {
  const { localCart } = props;

  console.log("localCart", localCart);
  const [cartDetails, setCartDetails] = useState({});

  useEffect(() => {
    var currCount = 0;
    var subTotal = 0;
    var totalPrice = 0;
    if (localCart) {
      localCart.forEach((element) => {
        currCount += parseFloat(element.quantity);
        subTotal += parseFloat(element.quantity) * parseFloat(element.price);
        totalPrice += parseFloat(element.quantity) * parseFloat(element.price);
      });
    }
    setCartDetails({
      count: currCount,
      subTotal: subTotal,
      fees: 0,
      taxable: 0,
      taxes: 0,
      totalPrice: totalPrice,
    });
  }, [localCart]);

  return (
    <div className="mt-6 px-1 sm:px-6 space-y-4">
      <div className="text-center">
        <h3>Summary</h3>
      </div>
      <div className="border border-gray-400 rounded-sm p-1 divide-y divide-gray-400">
        <div className="flex justify-between py-2">
          <p className="font-bold">Unit</p> <p>{cartDetails.count}</p>
        </div>
        <div className="flex justify-between py-2">
          <p className="font-bold">Subtotal</p> <p>${cartDetails.subTotal}</p>
        </div>
        <div className="flex justify-between py-2">
          <p className="font-bold">Fees</p> <p>${cartDetails.fees}</p>
        </div>
        <div className="flex justify-between py-2">
          <p className="font-bold">Taxable</p> <p>${cartDetails.taxable}</p>
        </div>
        <div className="flex justify-between py-2">
          <p className="font-bold">Taxes</p> <p>${cartDetails.taxes}</p>
        </div>
        <div className="flex justify-between py-2">
          <p className="font-bold">Total</p> <p>${cartDetails.totalPrice}</p>
        </div>
      </div>
      <div className="mt-2 space-y-3">Payment method???</div>
    </div>
  );
}
