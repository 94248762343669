import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";

import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";
import { SimpleNotificationType } from "../../../components/SimpleNotification";

export function SubCategoryDetailsViewModel(logout, categoryId) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState({ type: {} });
  const [primaryCategories, setPrimaryCategories] = useState([]);

  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      name: "Sub Categories",
      href: "/sub-categories",
      current: false,
    },
    { name: "Create New", href: "#", current: true },
  ]);

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });

  const getCategory = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(
      `products/subcategories/${categoryId}`,
      payload
    );
    if (callResult.success) {
      setCategory(callResult.data);
      setBreadCrumbs([
        {
          name: "Sub Categories",
          href: "/sub-categories",
          current: false,
        },
        { name: callResult.data.name, href: "#", current: true },
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  const createCategory = async function (values) {
    let callResult = await makePostApiCall(
      `products/subcategories/create`,
      values,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Product category created",
        type: SimpleNotificationType.Success,
      });
      window.location =
        "/sub-categories/" + callResult.data.id + "?created-new=true";
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateCategory = async function (values) {
    let callResult = await makePostApiCall(
      `updateSubCategory/${categoryId}`,
      values,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  // const deleteCustomer = async function (id, index) {
  //   setLoading(true);

  //   let callResult = await makePostApiCall(`deleteCustomer/${id}`, {});
  //   setLoading(false);
  //   if (callResult.success) {
  //     setEmptyModelOpen({
  //       open: false,
  //       childComponent: null,
  //     });
  //     setShowSimpleNotification({
  //       show: true,
  //       title: callResult.data.message,
  //       type: SimpleNotificationType.Success,
  //     });
  //   } else {
  //     if (callResult.errorStatus === 401) {
  //       logout();
  //     }
  //   }
  // };

  // const askDeleteCustomer = function (customer, index) {
  //   setEmptyModelOpen({
  //     childComponent: (
  //       <DeleteConfirmationForm
  //         buttonMessage="Delete"
  //         confirmAction={() => deleteCustomer(customer.id, index)}
  //         message={`Are you sure you want to delete ${customer.name}?`}
  //         setOpen={setEmptyModelOpen}
  //       />
  //     ),
  //     open: true,
  //   });
  // };

  const getPrimaryCategories = async function (search, page) {
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(
      `/products/categories/dropdown`,
      payload
    );
    if (callResult.success) {
      setPrimaryCategories(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    createCategory,
    updateCategory,
    getCategory,
    showSimpleNotification,
    setShowSimpleNotification,
    category,
    loading,
    emptyModal,
    getPrimaryCategories,
    primaryCategories,
    breadCrumbs,
  };
}
