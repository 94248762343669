import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { SimpleNotificationType } from "../../components/SimpleNotification";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";

export function ShippingDetailsViewModel(shippingId, logout) {
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [shipping, setShipping] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [invoiceInProgress, setInvoiceInProgress] = useState(false);

  const getShippingDetails = async function () {
    let callResult = await makePostApiCall(`fetchShipping/${shippingId}`);
    if (callResult.success) {
      const orderDetails = await onFetchOrderItems(callResult.data.order_id);
      if (orderDetails.success) {
        const order = orderDetails.data;
        setShipping({
          ...callResult.data,
          order_id: order.id,
          customer: order.customer,
          orderItems: [...order.order_items],
        });

        setLoading(false);
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const onFetchOrderItems = async function (id) {
    let callResult = await makePostApiCall(`fetchOrder/${id}`);
    if (callResult.success) {
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const updateShippingApiCall = async function (payload) {
    let callResult = await makePostApiCall(
      `updateShipping/${shippingId}`,
      payload
    );
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const onFetchItemByBarcode = async function (scannedBarcode) {
    let callResult = await makePostApiCall(
      `fetchItemByBarcode/${scannedBarcode}`
    );
    if (callResult.success) {
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const addItemToShipping = async function (
    shippingId,
    orderItemId,
    shippingItem
  ) {
    const payload = {
      shippingId: shippingId,
      orderItemId: orderItemId,
      shippingItem: shippingItem,
    };
    let callResult = await makePostApiCall("addItemToShipping", payload);
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Item added to shipping",
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const deleteShippingItemApiCall = async function (id) {
    let callResult = await makePostApiCall(`deleteShippingItem/${id}`);
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setEmptyModelOpen({
      open: false,
      childComponent: null,
    });
    return callResult;
  };

  const updateShippingAmountApiCall = async function (
    orderItemId,
    newShippingAmount
  ) {
    const payload = {
      order_item_id: orderItemId,
      new_shipping_amount: newShippingAmount,
    };
    let callResult = await makePostApiCall(
      "updateShippingAmountForOrderItem",
      payload
    );
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
    return callResult;
  };

  const getUsersForDropdown = async function (type, department) {
    const payload = { department: department, type: type };
    let callResult = await makePostApiCall(`usersForDropdown`, payload);

    if (callResult.success) {
      setEmployeeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const deleteShipping = async function () {
    let callResult = await deleteShipping(`shippings/delete/${shippingId}`);

    if (callResult.success) {
      window.location = "/shippings";
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.error);
    }
  };

  const printShipping = async function () {
    let callResult = await makePostApiCall(`printShipping/${shippingId}`);

    if (callResult.success) {
      window.open(callResult.data.location, "_blank");
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createInvoice = async function (orderId) {
    setInvoiceInProgress(true);
    let callResult = await makePostApiCall(`createInvoice/${orderId}`);

    if (callResult.success) {
      setShipping({
        ...shipping,
        invoice_number: callResult.data.invoice_number,
      });
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
    setInvoiceInProgress(false);
  };

  function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  function saveByteArray(reportName, byte) {
    var blob = new Blob([byte], { type: "application/pdf" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
  }

  const downloadInvoice = async function (orderId) {
    setInvoiceInProgress(true);
    // let callResult = await makePostApiCall(`readInvoiceFromQb/${orderId}`);
    let callResult = await makePostApiCall(`readInvoiceFromQb`);

    if (callResult.success) {
      var sampleArr = base64ToArrayBuffer(callResult.data);
      saveByteArray("Sample Report", sampleArr);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
    setInvoiceInProgress(false);
  };

  return {
    onFetchOrderItems,
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    updateShippingApiCall,
    shipping,
    onFetchItemByBarcode,
    setShipping,
    addItemToShipping,
    getShippingDetails,
    deleteShippingItemApiCall,
    updateShippingAmountApiCall,
    employeeOptions,
    getUsersForDropdown,
    deleteShipping,
    printShipping,
    createInvoice,
    invoiceInProgress,
    downloadInvoice,
  };
}
