import React, { useState } from "react";

import SelectMenu from "../../../controls/SelectMenu";

export default function CustomersBulkActions(props) {
  const { confirmAction, setOpen, customerGroupOptions } = props;

  const [selectedGroup, setSelectedGroup] = useState(null);

  return (
    <div className="justify-between">
      <div>
        <div className="flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Bulk actions
            </h3>
          </div>
        </div>

        <div className="py-1 gap-4 bg-white sm:rounded-xl">
          <div className="grid grid-cols-1 gap-4 w-full">
            <SelectMenu
              handleInputChange={(e) => setSelectedGroup(e.target.value)}
              name="customer_groups"
              options={customerGroupOptions}
              title={"Customer Group"}
              isMulti={true}
            />
          </div>

          <div className="sm:flex-no-wrap my-auto flex items-center">
            <button
              onClick={() => {
                confirmAction({ customer_groups: selectedGroup });
                setOpen({
                  open: false,
                });
              }}
              className="inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center"
            >
              OK
            </button>
            <button
              onClick={() =>
                setOpen({
                  open: false,
                })
              }
              className="ml-2 inline-flex justify-center mt-3 py-35-custom px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 items-center"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
