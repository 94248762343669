import { useEffect, useState } from "react";
import { classNames } from "../../shared/utility";

// const pages = [
//   { name: "Projects", href: "#", current: false },
//   { name: "Project Nero", href: "#", current: true },
// ];

export default function BreadCrumbs(props) {
  const { pgs } = props;
  const [pages, setPages] = useState([]);

  useEffect(() => {
    setPages(pgs);
  }, [pgs]);

  return (
    <>
      {pages.length > 0 ? (
        <nav
          aria-label="Breadcrumb"
          className="flex border-b border-gray-200 bg-white"
        >
          <ol className="flex w-full max-w-screen-xl space-x-4 px-4">
            {pages.map((page, index) => (
              <li key={page.name} className="flex">
                <div className="flex items-center">
                  {index > 0 ? (
                    <svg
                      fill="currentColor"
                      viewBox="0 0 24 44"
                      preserveAspectRatio="none"
                      aria-hidden="true"
                      className="h-full w-6 flex-shrink-0 text-gray-200"
                    >
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                  ) : null}
                  <a
                    href={page.href}
                    aria-current={page.current ? "page" : undefined}
                    className={classNames(
                      index > 0 ? "ml-4" : null,
                      "text-sm font-medium text-gray-500 hover:text-gray-700"
                    )}
                    onClick={() => (page.onClick ? page.onClick(pgs) : null)}
                  >
                    {page.name}
                  </a>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      ) : null}
    </>
  );
}
