import { useEffect, useState } from "react";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { withRouter } from "../../../shared/utility";

import { useParams } from "react-router-dom";

import SimpleNotification from "../../../components/SimpleNotification";
import { ProductCategoryDetailsViewModel } from "../../../viewmodels/primaryCategories/category/ProductCategoryDetailsViewModel";
import ProductCategoryDetailsForm from "../../../components/forms/primaryCategories/ProductCategoryDetailsForm";
import BreadCrumbs from "../../../components/controls/BreadCrumbs";

function ProductCategoryDetails(props) {
  const { logout, isEdit } = props;

  let { categoryId } = useParams();

  const {
    createCategory,
    updateCategory,
    getCategory,
    showSimpleNotification,
    setShowSimpleNotification,
    category,
    loading,
    emptyModal,
    breadCrumbs,
  } = ProductCategoryDetailsViewModel(logout, categoryId);

  const onStartDependencides = async function () {
    if (isEdit) {
      getCategory();
    }
  };

  useEffect(() => {
    onStartDependencides();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-4">
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />

      <BreadCrumbs pgs={breadCrumbs} />

      <div className="card-custom">
        {loading ? null : (
          <ProductCategoryDetailsForm
            isEdit={categoryId}
            category={category}
            actionCall={(values) => {
              if (categoryId) {
                updateCategory(values);
              } else {
                createCategory(values);
              }
            }}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductCategoryDetails)
);
