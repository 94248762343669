import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";

import { SimpleNotificationType } from "../../components/SimpleNotification";
import CartForm from "../forms/cart/CartForm";

export function DefaultLayoutViewModel(
  cart,
  setCart,
  setCartCustomer,
  cartCustomer,
  logout
) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const createOrderFromCart = async function (payload) {
    let callResult = await makePostApiCall(`createOrderFromCart`, payload);
    if (callResult.success) {
      window.location = `/orders/${callResult.data.id}`;

      setCart([]);
      setCartCustomer({});
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openCart = () => {
    setSlideOver({
      childComponent: (
        <CartForm
          cart={cart}
          cartCustomer={cartCustomer}
          isEdit={true}
          setOpen={setSlideOver}
          actionCall={createOrderFromCart}
          setCart={setCart}
          setCartCustomer={setCartCustomer}
        />
      ),
      open: true,
    });
  };

  return {
    emptyModal,
    setEmptyModelOpen,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    openCart,
  };
}
