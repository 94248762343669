import React, { useEffect, useState } from "react";

import { useForm } from "../useForm";

import AsyncSelect from "react-select/async";

import Input from "../../controls/Input";
import SelectMenu from "../../controls/SelectMenu";
import OrderProductsTable from "./OrderProductsTable";
import DeleteConfirmationForm from "../DeleteConfirmationForm";
import DatePickerInput from "../../controls/DatePickerInput";
import AddProductToOrderForm from "./modals/AddProductToOrderForm";
import OrderNotes from "./modals/OrderNotes";
import {
  convertToDefEventPara,
  orderStatusOptions,
} from "../../../shared/utility";
import { classNames } from "primereact/utils";

const initialFValues = {
  id: 0,
  customer: "",
  customer_id: 0,
  subtotal_dollar_amount: 0,
  total_dollar_amount: 0,
  order_date: new Date(),
  order_items: [],
};

export default function OrderDetailsForm(props) {
  const {
    customers,
    getMyProducts,
    employeeOptions,
    isEdit,
    loading,
    order,
    setEmptyModelOpen,
    showErrorModal,
    updateOrderApiCall,
    createOrderApiCall,
    onUpdateOrderItem,
    deleteOrderItemApiCall,
    addProductToOrderApiCall,
    printOrder,
    createEstimate,
  } = props;

  const [asyncSelectValue, setAsyncSelectValue] = useState({
    value: -1,
    label: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.customer = null;
    if (values.customer === "") {
      temp.customer = "Please fill out field.";
    }

    temp.expected_date = null;
    if (values.expected_date === "") {
      temp.expected_date = "Please fill out field.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  useEffect(() => {
    if (order) {
      const orderCopy = {
        ...order,
        status: orderStatusOptions.filter(
          (option) => option.value === order.status
        )[0],
      };
      setValues(orderCopy);
    }
  }, [order]);

  function findIndexForProduct(product_id) {
    return values.order_items.findIndex(
      (element) => element.product_id === product_id
    );
  }

  const addProductToOrder = async function (addedProductValues) {
    setAsyncSelectValue({ value: -1, label: "" });
    const productToAdd = {
      id: -1,
      product_name: addedProductValues.product_name,
      product_id: addedProductValues.product_id,
      quantity: addedProductValues.quantity,
      individual_unit_size: addedProductValues.individual_unit_size,
      selling_unit_of_measure: addedProductValues.selling_unit_of_measure,
      plu: addedProductValues.plu,
      description: addedProductValues.description,
      unit_price: addedProductValues.unit_price,
      group_price: addedProductValues.group_price,
      quickbooks_id: addedProductValues.quickbooks_id,
    };

    const productIndex = findIndexForProduct(productToAdd.product_id);
    if (productIndex > -1) {
      showErrorModal("Product already in order, please edit it.");
      return;
    }

    let callResult = { success: false };
    let newOrderProducts = [];
    if (isEdit) {
      callResult = await addProductToOrderApiCall(values.id, productToAdd);
      newOrderProducts = [...values.order_items, callResult.data];
    } else {
      newOrderProducts = [...values.order_items, productToAdd];
    }

    if (callResult || !isEdit) {
      setValues({
        ...values,
        order_items: newOrderProducts,
      });
      setEmptyModelOpen({
        childComponent: null,
        open: false,
      });
    }
  };

  const updateOrder = () => {
    if (validate()) {
      updateOrderApiCall(values);
    }
  };

  const createOrder = () => {
    if (validate()) {
      createOrderApiCall(values);
    }
  };

  const updateItemLocally = (newData, index) => {
    let currentOrders = [...values.order_items];
    currentOrders[index] = newData;
    setValues({ ...values, order_items: currentOrders });
  };

  const onItemEditComplete = async function (newData, index) {
    if (isEdit) {
      const onUpdateCompleted = await onUpdateOrderItem(
        newData.id,
        newData.quantity
      );

      if (onUpdateCompleted.success) {
        updateItemLocally(newData, index);
      }
    }
  };

  const makeDeleteOrderItemApiCall = async function (itemId) {
    var onItemDeleted = { success: false };
    if (isEdit) {
      onItemDeleted = await deleteOrderItemApiCall(itemId);
    }

    if (onItemDeleted.success || !isEdit) {
      const filteredItems = values.order_items.filter(
        (item) => item.id !== itemId
      );
      setValues({ ...values, order_items: filteredItems });
    }
    setEmptyModelOpen({
      childComponent: null,
      open: false,
    });
  };

  const askDeleteOrderItem = function (product) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => makeDeleteOrderItemApiCall(product.id)}
          message={`Are you sure you want to ${product.product_name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const loadOptions = async function (input, callback) {
    if (input.length > 2) {
      const result = await getMyProducts(input);
      callback(result);
    }
  };

  const handleSubmit = () => {
    if (values.id > 0) {
      updateOrder();
    } else {
      createOrder();
    }
  };

  return (
    <div>
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold leading-6 text-gray-900">
          Editing Order {order ? order.po_number : null}
        </h1>
      </div>
      <div className="mt-5 grid grid-cols-3 gap-3 h-full">
        <div className="col-span-2">
          <div className="card-custom p-3 py-5">
            <div className="flex rounded-md">
              <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                options={[]}
                className="w-full"
                onChange={(e) => {
                  setEmptyModelOpen({
                    childComponent: (
                      <AddProductToOrderForm
                        product={e}
                        confirmAction={addProductToOrder}
                        isEdit={false}
                        setOpen={() => {
                          setEmptyModelOpen({
                            open: false,
                          });
                          setAsyncSelectValue({ value: -1, label: "" });
                        }}
                      />
                    ),
                    open: true,
                  });
                }}
                value={asyncSelectValue}
              />
            </div>

            <OrderProductsTable
              addProductToOrder={addProductToOrder}
              loading={false}
              values={values}
              setEmptyModelOpen={setEmptyModelOpen}
              onItemEditComplete={onItemEditComplete}
              askDeleteOrderItem={askDeleteOrderItem}
            />
          </div>
        </div>

        <div className="col-span-1 space-y-5">
          <div className="card-custom p-3 space-y-2">
            <SelectMenu
              handleInputChange={handleInputChange}
              name="customer"
              options={customers}
              defaultValue={isEdit ? order.customer : null}
              title={"Customer"}
              error={errors.customer}
              showLabel={true}
            />

            <Input
              label="PO #"
              name="po_number"
              onChange={handleInputChange}
              value={values.po_number}
              labelOn={true}
              type="text"
              error={errors.po_number}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />

            <SelectMenu
              handleInputChange={handleInputChange}
              name="created_by"
              options={employeeOptions}
              defaultValue={isEdit ? order.created_by : null}
              title={"Employee"}
              error={errors.created_by}
              showLabel={true}
            />

            <div className="mt-3 flex flex-shrink-0 py-4">
              {/* <button
              type="button"
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
              onClick={() => {}}
            >
              Cancel
            </button> */}
              <button
                type="button"
                className={classNames(
                  values.id > 0 ? "" : null,
                  "inline-flex justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 mr-4"
                )}
                onClick={() => printOrder()}
              >
                Print
              </button>
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mr-4"
                onClick={handleSubmit}
              >
                {values.id > 0 ? "Save" : "Create"}
              </button>
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => createEstimate()}
              >
                Estimate
              </button>
            </div>
          </div>
          <div className="card-custom p-3 space-y-2">
            <DatePickerInput
              label="Order Date"
              name="order_date"
              onChange={handleInputChange}
              value={values.order_date}
              labelOn={true}
              minDate={new Date()}
              dateFormat="LLL dd, YYY"
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              error={errors.order_date}
            />

            <DatePickerInput
              label="Expected Date"
              name="expected_date"
              onChange={handleInputChange}
              value={values.expected_date ? values.expected_date : null}
              labelOn={true}
              minDate={new Date()}
              dateFormat="LLL dd, YYY"
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
              error={errors.expected_date}
            />

            {isEdit ? (
              <SelectMenu
                handleInputChange={handleInputChange}
                name="status"
                options={orderStatusOptions.filter(
                  (option) => option.value !== -1
                )}
                value={isEdit ? values.status : null}
                title={"Status"}
                error={errors.status}
                showLabel={true}
              />
            ) : null}
          </div>
          <div className="card-custom p-3 mt-3">
            <div className="flex justify-between">
              <p className="text-sm text-gray-700 font-semibold">Notes</p>
              <button
                onClick={() =>
                  setEmptyModelOpen({
                    childComponent: (
                      <OrderNotes
                        // buttonMessage="Delete"
                        // confirmAction={() => makeDeleteOrderItemApiCall(product.id)}
                        // message={`Are you sure you want to ${product.product_name}?`}
                        orderNote={values.notes}
                        confirmAction={(newNote) =>
                          handleInputChange(
                            convertToDefEventPara("notes", newNote)
                          )
                        }
                        setOpen={setEmptyModelOpen}
                      />
                    ),
                    open: true,
                  })
                }
                className="text-sm font-semibold main-purple"
              >
                Edit
              </button>
            </div>
            <p className="text-sm text-gray-700 font-normal">
              {values.notes ? values.notes : "No notes"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
