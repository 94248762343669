import React, { useState } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames, formatDateWithTime } from "../../../shared/utility";
import Input from "../../controls/Input";

export default function OrderItemsForShipping(props) {
  const {
    updateShippingAmountForItem,
    updateShippingAmountApiCall,
    orderItems,
    isEdit,
    askDeleteShippingItem,
  } = props;

  const [isFocused, setIsFocused] = useState(-1);
  const [error, setError] = useState({
    id: -1,
    message: null,
  });

  const [expandedRows, setExpandedRows] = useState(null);

  const allowExpansion = (rowData) => {
    const shippingItems = rowData.shippingItems ? rowData.shippingItems : [];
    return shippingItems.length > 0;
  };

  const shippedAmountBody = (rowData) => {
    return (
      <div className="flex">
        <Input
          name="shipped_amount"
          onChange={(e) => updateShippingAmountForItem(rowData, e.target.value)}
          value={rowData.shipped_amount}
          onFocus={() => {
            setIsFocused(rowData.id);
          }}
          labelOn={false}
          error={error.id === rowData.id ? error.message : null}
          className="block w-16 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full"
        />
        <button
          type="submit"
          className={classNames(
            isFocused === rowData.id && isEdit ? null : "hidden",
            "ml-2 justify-center rounded-md bg-main-purple px-3 py-2 my-auto text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          )}
          onClick={() => {
            if (parseFloat(rowData.shipped_amount) >= 0) {
              updateShippingAmountApiCall(rowData.id, rowData.shipped_amount);
            } else {
              setError({
                id: rowData.id,
                message: "Amount must be 0 or more",
              });
            }
          }}
        >
          Save
        </button>
      </div>
    );
  };

  const productDetailsBody = (rowData) => {
    return (
      <p>
        <span className="text-gray-900 font-semibold">
          {rowData.product_name}
        </span>
        <br />
        <span className="text-gray-700">PLU:{rowData.plu}</span>
      </p>
    );
  };

  const orderQuantityBody = (rowData) => {
    return <p>{rowData.quantity} Case(s)</p>;
  };

  const shippingItemActionTemplate = (rowData) => {
    return (
      <div className="ml-10">
        <button
          onClick={() => askDeleteShippingItem(rowData)}
          className="ml-2 rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          Delete
        </button>
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        {/* <h5>Orders for {data.name}</h5> */}
        <DataTable
          value={data.shippingItems}
          tableClassName="min-w-full bg-white divide-y divide-gray-300"
          cellClassName="py-2 bg-gray-100 border-1-bottom-gray px-2"
        >
          <Column field="shipping_barcode" header="Barcode"></Column>

          <Column header="" body={shippingItemActionTemplate} />
        </DataTable>
      </div>
    );
  };

  return (
    <div className="mt-5 flow-root">
      <div className="sm:-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <DataTable
              value={orderItems}
              expandedRows={expandedRows}
              onRowToggle={(e) => {
                setExpandedRows(e.data);
              }}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="id"
              tableClassName="min-w-full bg-white divide-y divide-gray-300 text-sm"
              cellClassName="p-2 bg-white border-1-bottom-gray text-sm"
            >
              <Column
                header="Product"
                body={productDetailsBody}
                style={{ width: "40%" }}
              />
              <Column
                header="Quantity"
                body={orderQuantityBody}
                style={{ width: "20%" }}
              />
              <Column
                body={shippedAmountBody}
                header="Amount being shipped"
                style={{ width: "30%" }}
              ></Column>
              <Column expander={allowExpansion} style={{ width: "5rem" }} />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
