import { useEffect, useState } from "react";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "../useForm";
import { updateObjectInArray } from "../../../shared/utility";
import ItemsScreen from "./ItemsScreen";
import OrderSummary from "./OrderSummary";
import OrderDetails from "./OrderDetails";
import OrderCreatedBy from "./OrderCreatedBy";

const initialFValues = {};

const screens = [
  { name: "Items Screen", href: "#" },
  { name: "Order Summary", href: "#" },
  { name: "Order Details", href: "#" },
];

export default function CartForm(props) {
  const { cart, cartCustomer, setCartCustomer, setOpen, actionCall, setCart } =
    props;

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const [localCart, setLocalCart] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    actionCall({ cart: localCart, customer: cartCustomer });
  };

  useEffect(() => {
    if (cart) {
      setLocalCart(cart);
    }
  }, []);

  useEffect(() => {
    if (cartCustomer) {
      setOrderDetails(cartCustomer);
    }
  }, [cartCustomer]);

  const onProductQuantityChanged = (name, newValue, index) => {
    const copyProducts = [...localCart];
    const singleItem = copyProducts[index];

    var total = singleItem.total;
    if (name === "price") {
      total = newValue * singleItem.quantity;
    } else if (name === "quantity") {
      total = newValue * singleItem.price;
    }

    const singleItemBeingEdited = {
      ...singleItem,
      [name]: newValue,
      total: total,
    };
    var payload = {
      index: index,
      item: singleItemBeingEdited,
    };
    const newProducts = updateObjectInArray(copyProducts, payload);
    setLocalCart(newProducts);
    setCart(newProducts);
  };

  const onRemoveProductFromCart = (product, index) => {
    const newProducts = localCart.filter((el, elIndex) => elIndex !== index);
    setLocalCart(newProducts);
    setCart(newProducts);
  };

  const [currScreen, setCurrScreen] = useState(0);

  const onCustomerRelatedChange = (name, value) => {
    const selectedCust = {
      ...orderDetails,
      [name]: value,
    };
    setOrderDetails(selectedCust);
    setCartCustomer(selectedCust);
  };

  const renderComponent = (index) => {
    switch (index) {
      case 0:
        return (
          <div key={0}>
            <ItemsScreen
              localCart={localCart}
              onRemoveProductFromCart={onRemoveProductFromCart}
              onProductQuantityChanged={onProductQuantityChanged}
            />
          </div>
        );

      case 1:
        return (
          <div key={1}>
            <OrderSummary localCart={localCart} />
          </div>
        );

      case 2:
        return (
          <div key={2}>
            <OrderDetails
              orderDetails={orderDetails}
              errors={errors}
              handleInputChange={handleInputChange}
              onCustomerRelatedChange={onCustomerRelatedChange}
            />
          </div>
        );

      default:
        return <></>;
    }
  };
  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              Cart for {cartCustomer.company_name}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        {screens.map((item, index) =>
          index === currScreen ? renderComponent(index) : null
        )}
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          disabled={currScreen === 0}
          onClick={() => setCurrScreen(currScreen - 1)}
        >
          Previous
        </button>
        {currScreen < screens.length - 1 ? (
          <button
            type="button"
            className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
            onClick={() => setCurrScreen(currScreen + 1)}
          >
            Next
          </button>
        ) : (
          <button
            type="submit"
            className="ml-4 inline-flex justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmit}
          >
            Create order
          </button>
        )}
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Close
        </button>
        <button
          type="button"
          className="ml-4 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => {
            setLocalCart([]);
            setCart([]);
          }}
        >
          Clear
        </button>
      </div>
    </div>
  );
}
