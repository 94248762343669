import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

import { classNames, formatDateWithTime } from "../../../shared/utility";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";

export default function ShippedOrdersCard(props) {
  const { info } = props;

  return (
    <li className="overflow-hidden rounded-xl border border-gray-200 shadow-md">
      <div className="flex infos-center gap-x-4 border-b border-gray-900/5 bg-gray-50 px-6 py-4">
        <div className="text-sm font-medium leading-6 text-gray-900">
          {info.name}
        </div>
        <Menu as="div" className="relative ml-auto">
          <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Open options</span>
            <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          </MenuButton>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <MenuItem>
                {({ focus }) => (
                  <button
                    className={classNames(
                      focus ? "bg-gray-50" : "",
                      "block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-start"
                    )}
                  >
                    View<span className="sr-only">, {info.name}</span>
                  </button>
                )}
              </MenuItem>
              <MenuItem>
                {({ focus }) => (
                  <button
                    href="#"
                    className={classNames(
                      focus ? "bg-gray-50" : "",
                      "block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-start"
                    )}
                  >
                    Edit<span className="sr-only">, {info.name}</span>
                  </button>
                )}
              </MenuItem>
            </MenuItems>
          </Transition>
        </Menu>
      </div>

      <div className="overflow-hidden bg-white h-full">
        <table className="min-w-full">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                style={{ width: "33%" }}
              >
                PO #
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                style={{ width: "33%" }}
              >
                Customer
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                style={{ width: "33%" }}
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {info.data.length > 0 ? (
              info.data.map((product) => (
                <tr key={product.item1}>
                  <td className="py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {product.item1}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {product.item2}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {formatDateWithTime(product.item3)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center"></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </li>
  );
}

{
  /* <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        {info.data.map((item) => (
          <div className="flex justify-between gap-x-4 py-3">
            <dt className="text-gray-500">{item.item1}</dt>
            <dd className="text-gray-700">{item.item2}</dd>
            {item.item3 ? (
              <dd className="text-gray-700">{item.item3}</dd>
            ) : null}
          </div>
        ))}
      </dl> */
}
