import { useEffect } from "react";
import { useForm } from "../useForm";

import Input from "../../controls/Input";
import { convertToDefEventPara } from "../../../shared/utility";
import SelectMenu from "../../controls/SelectMenu";

const initialFValues = {
  customer_name: "",
  email: "",
  given_name: "",
  middle_name: "",
  family_name: "",
  bill_with_parent: "",
  primary_phone_number: "",
  active: 0,
  customer_groups: [],
  allocated_to: [],
  image: "",
};

export default function CustomerDetailsForm(props) {
  const { customer, customerGroupOptions, actionCall, usersOptions } = props;

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();
    actionCall(values);
  };

  useEffect(() => {
    if (customer) {
      setValues(customer);
    }
  }, [customer, setValues]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="p-4">
        <div className="space-y-3">
          <div>
            <div className="mt-3 grid grid-cols-1 gap-4 max-w-4xl">
              <div>
                <Input
                  label="Company Name"
                  name="company_name"
                  onChange={handleInputChange}
                  value={values.company_name}
                  labelOn={true}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4 max-w-4xl">
              <div className="col-span-2 flex">
                <input
                  id="active"
                  aria-describedby="selectAllBox"
                  name="active"
                  type="checkbox"
                  checked={values.active}
                  className="mt-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onClick={(e) =>
                    handleInputChange(
                      convertToDefEventPara("active", e.target.checked)
                    )
                  }
                />
                Active
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4 max-w-4xl">
              <SelectMenu
                handleInputChange={handleInputChange}
                name="customer_groups"
                defaultValue={customer.customer_groups}
                options={customerGroupOptions}
                title={"Customer Group"}
                isMulti={true}
              />
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4 max-w-4xl">
              <SelectMenu
                handleInputChange={handleInputChange}
                name="allocated_to"
                defaultValue={customer.allocated_to}
                options={usersOptions}
                title={"Allocated to"}
                isMulti={true}
              />
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4 max-w-4xl">
              <div className="col-span-2 flex">
                <input
                  id="taxable"
                  aria-describedby="selectAllBox"
                  name="taxable"
                  type="checkbox"
                  checked={values.taxable}
                  className="mt-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onClick={(e) =>
                    handleInputChange(
                      convertToDefEventPara("taxable", e.target.checked)
                    )
                  }
                />
                Taxable
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
              <div>
                <Input
                  label="Quickbooks Id"
                  name="quickbooks_id"
                  onChange={handleInputChange}
                  value={values.quickbooks_id}
                  labelOn={true}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
              <div>
                <Input
                  label="Billing Email Address"
                  name="email"
                  onChange={handleInputChange}
                  value={values.email}
                  labelOn={true}
                  disabled={true}
                />
              </div>
              <div>
                <Input
                  label="Phone Number"
                  name="primary_phone_number"
                  onChange={handleInputChange}
                  value={values.primary_phone_number}
                  labelOn={true}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-3 gap-4 max-w-4xl">
              <div>
                <Input
                  label="Contact First Name"
                  name="given_name"
                  onChange={handleInputChange}
                  value={values.given_name}
                  labelOn={true}
                  disabled={true}
                />
              </div>
              <div>
                <Input
                  label="Contact First Name"
                  name="middle_name"
                  onChange={handleInputChange}
                  value={values.middle_name}
                  labelOn={true}
                  disabled={true}
                />
              </div>
              <div>
                <Input
                  label="Contact First Name"
                  name="family_name"
                  onChange={handleInputChange}
                  value={values.family_name}
                  labelOn={true}
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="sm:flex-no-wrap">
            <div className="my-auto flex items-center">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center mt-3"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
