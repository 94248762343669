import DatePickerInput from "../controls/DatePickerInput";

export default function DateRangeFilter(props) {
  const { onChange, resetFilter, startDate, endDate } = props;

  return (
    <div className="p-3">
      <div className="flex justify-between">
        <p className="text-sm text-gray-700 font-semibold">Date Range</p>
        <button
          onClick={() => resetFilter()}
          className="text-sm font-semibold main-purple"
        >
          Reset
        </button>
      </div>
      <div className="grid grid-cols-2 gap-3 mt-1">
        <DatePickerInput
          label="From:"
          name="startDate"
          onChange={(e) => onChange("startDate", e.target.value)}
          value={startDate}
          labelOn={true}
          type="text"
          dateFormat={"LLL dd, YYY"}
          labelDivClass="mb-0"
          labelClass="text-xs text-gray-500"
        />
        <DatePickerInput
          label="To:"
          name="endDate"
          onChange={(e) => onChange("endDate", e.target.value)}
          minDate={startDate}
          value={endDate}
          labelOn={true}
          type="text"
          dateFormat={"LLL dd, YYY"}
          labelDivClass="mb-0"
          labelClass="text-xs text-gray-500"
        />
      </div>
    </div>
  );
}
