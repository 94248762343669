import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";

import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";
import { SimpleNotificationType } from "../../../components/SimpleNotification";

export function ProductDetailsViewModel(logout, productId) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [primaryCategoryOptions, setPrimaryCategoryOptions] = useState(null);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState(null);
  const [priceList, setPriceList] = useState(null);

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getProduct = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`products/${productId}`, payload);
    if (callResult.success) {
      const primaryCat = callResult.data.primary_category;
      setProduct(callResult.data);
      let subCatResult = await getSubCategoriesByPrimary(primaryCat);
      if (subCatResult) {
        setSubCategoryOptions(subCatResult.data);
      } else {
        if (callResult.errorStatus === 401) {
          logout();
        }
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  const updateProduct = async function (values) {
    const payload = {
      ...values,
      priceList: priceList,
    };
    let callResult = await makePostApiCall(
      `updateProduct/${productId}`,
      payload,
      "multipart/form-data"
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Product updated",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getPriceListForProduct = async function () {
    setLoading(true);

    let callResult = await makePostApiCall(`pricelist/${productId}`);
    setLoading(false);
    if (callResult.success) {
      setPriceList(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  // const deleteProduct = async function (id, index) {
  //   setLoading(true);

  //   let callResult = await makePostApiCall(`deleteProduct/${id}`, {});
  //   setLoading(false);
  //   if (callResult.success) {
  //     setEmptyModelOpen({
  //       open: false,
  //       childComponent: null,
  //     });
  //     setShowSimpleNotification({
  //       show: true,
  //       title: callResult.data.message,
  //       type: SimpleNotificationType.Success,
  //     });
  //   } else {
  //     if (callResult.errorStatus === 401) {
  //       logout();
  //     }
  //   }
  // };

  // const askDeleteProduct = function (product, index) {
  //   setEmptyModelOpen({
  //     childComponent: (
  //       <DeleteConfirmationForm
  //         buttonMessage="Delete"
  //         confirmAction={() => deleteProduct(product.id, index)}
  //         message={`Are you sure you want to delete ${product.name}?`}
  //         setOpen={setEmptyModelOpen}
  //       />
  //     ),
  //     open: true,
  //   });
  // };

  const getPrimaryCategoriesForDropdown = async function () {
    setLoading(true);

    let callResult = await makePostApiCall(`products/categories/dropdown`);
    setLoading(false);
    if (callResult.success) {
      setPrimaryCategoryOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getUsersForDropdown = async function () {
    setLoading(true);

    let callResult = await makePostApiCall(`usersForDropdown`);
    setLoading(false);
    if (callResult.success) {
      setUsersOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getSubCategoriesByPrimary = async function (categoryId) {
    let callResult = await makePostApiCall(
      `/products/subcategories/dropdown/${categoryId}`
    );
    setLoading(false);
    if (callResult.success) {
      setSubCategoryOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    primaryCategoryOptions,
    getPrimaryCategoriesForDropdown,
    updateProduct,
    getProduct,
    slideOver,
    setSlideOver,
    showSimpleNotification,
    setShowSimpleNotification,
    product,
    loading,
    emptyModal,
    getUsersForDropdown,
    usersOptions,
    getSubCategoriesByPrimary,
    subCategoryOptions,
    getPriceListForProduct,
    priceList,
    setPriceList,
  };
}
