import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";

import { SimpleNotificationType } from "../../components/SimpleNotification";

export function CustomersViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState({
    data: [],
  });
  const [customerGroupOptions, setCustomerGroupOptions] = useState(null);

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getCustomers = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`customers`, payload);
    setLoading(false);
    if (callResult.success) {
      setCustomers(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getCustomerGroupsForDropdown = async function () {
    setLoading(true);

    let callResult = await makePostApiCall(`getCustomersGroupsForDropdown`);
    setLoading(false);
    if (callResult.success) {
      setCustomerGroupOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onBulkAction = async function (bulkActionValues) {
    let callResult = await makePostApiCall(
      `bulkAction/customers`,
      bulkActionValues
    );
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: SimpleNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    emptyModal,
    setEmptyModelOpen,
    getCustomers,
    loading,
    customers,
    setSlideOver,
    setShowSimpleNotification,
    slideOver,
    showSimpleNotification,
    getCustomerGroupsForDropdown,
    customerGroupOptions,
    onBulkAction,
  };
}
