import {
  Cog6ToothIcon,
  HomeIcon,
  ShoppingBagIcon,
  ScaleIcon,
  TruckIcon,
  ShoppingCartIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

export default function routes(pathname, userType) {
  var title;
  var routes;

  routes = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Customers",
      icon: UserGroupIcon,
      current: false,
      showInMenu: true,
      children: [
        { name: "Manage Customers", href: "/customers", current: false },
        { name: "Manage Groups", href: "/customer-groups", current: false },
      ],
    },
    {
      name: "Products",
      icon: ShoppingBagIcon,
      current: false,
      showInMenu: true,
      open: false,
      children: [
        { name: "Manage Products", href: "/products" },
        { name: "Primary Categories", href: "/primary-categories" },
        { name: "Sub Categories", href: "/sub-categories" },
      ],
    },
    {
      name: "Orders",
      icon: ShoppingCartIcon,
      current: false,
      showInMenu: true,
      open: false,
      children: [
        { name: "Create", href: "/create-order" },
        { name: "Manage Orders", href: "/orders" },
      ],
    },
    {
      name: "Shipping",
      icon: TruckIcon,
      current: false,
      showInMenu: true,
      open: false,
      children: [
        { name: "Create Shipping", href: "/create-shipping" },
        { name: "History", href: "/shippings" },
      ],
    },
    {
      name: "Setup",
      icon: Cog6ToothIcon,
      current: false,
      showInMenu: true,
      open: false,
      children: [
        { name: "Loblaws Skus", href: "/loblaws-skus", current: false },
        { name: "Users", href: "/users", current: false },
      ],
    },
  ];

  routes.forEach((route) => {
    route.open = false;
    if (route.children) {
      route.children.forEach((child) => {
        child.current = false;
        if (child.href === pathname) {
          route.open = true;
          child.current = true;

          title = child.name;
        }
      });
    } else if (route.href === pathname) {
      route.current = true;

      title = route.name;
    }
  });

  var returnArray = {
    routes: routes.filter((route) => route.showInMenu),
    title: title,
  };

  return returnArray;
}
