import { useEffect, useState } from "react";

import Input from "../../controls/Input";
import SelectMenu from "../../controls/SelectMenu";
import TextArea from "../../controls/TextArea";
import {
  convertToDefEventPara,
  sellingUnitOfMeasureOptions,
} from "../../../shared/utility";
import ImportMediaDropzone from "../../importMedia/ImportMediaDropzone";

export default function ProductDetailsForm(props) {
  const {
    product,
    primaryCategoryOptions,
    subCategoryOptions,
    getSubCategoriesByPrimary,
    setValues,
    values,
    handleSubmit,
    handleInputChange,
  } = props;

  const onPrimaryCategoryChanged = (e) => {
    setValues({
      ...values,
      primary_category: e.target.value.value,
      sub_category_arr: { value: -1, label: "" },
    });
    getSubCategoriesByPrimary(e.target.value.value);
  };

  useEffect(() => {
    if (product) {
      var sub_cat_arr = subCategoryOptions.filter(
        (option) => option.value === product.sub_category
      );
      if (sub_cat_arr.length > 0) {
        sub_cat_arr = sub_cat_arr[0];
      }
      setValues({
        ...product,
        sub_category_arr: sub_cat_arr,
      });
    }
  }, [product]);

  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);

  return (
    <form onSubmit={handleSubmit}>
      <div className="p-4">
        <div className="space-y-3">
          <div>
            <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
              <Input
                label="Product Name"
                name="name"
                onChange={handleInputChange}
                value={values.name}
                labelOn={true}
                disabled={true}
              />
              <Input
                label="Sku"
                name="plu"
                onChange={handleInputChange}
                value={values.plu}
                labelOn={true}
                disabled={true}
              />
            </div>
            <div className="mt-3 grid grid-cols-1 gap-4 max-w-4xl">
              <TextArea
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm max-w-lg"
                labelOn={true}
                name="description"
                value={values.description}
                disabled={true}
                rows="3"
              />
            </div>
            <div className="mt-3 grid grid-cols-5 gap-4 max-w-4xl">
              <Input
                label="Taxable"
                name="taxable"
                onChange={handleInputChange}
                value={values.taxable === 0 ? "No" : "Yes"}
                labelOn={true}
                disabled={true}
              />
              <Input
                label="Cost"
                name="cost"
                onChange={handleInputChange}
                value={values.cost}
                labelOn={true}
                disabled={true}
              />
              <Input
                label="Unit price"
                name="unit_price"
                onChange={handleInputChange}
                value={values.unit_price}
                labelOn={true}
                disabled={true}
              />
              <Input
                label="Stock"
                name="stock"
                onChange={handleInputChange}
                value={values.stock}
                labelOn={true}
                disabled={true}
              />

              <SelectMenu
                handleInputChange={(e) =>
                  handleInputChange(
                    convertToDefEventPara(
                      "selling_unit_of_measure",
                      e.target.value.value
                    )
                  )
                }
                name="selling_unit_of_measure"
                defaultValue={sellingUnitOfMeasureOptions.filter(
                  (option) => option.value === product.selling_unit_of_measure
                )}
                options={sellingUnitOfMeasureOptions}
                title={"Unit of measure"}
              />
            </div>
            {/* <div className="mt-3 grid grid-cols-1 gap-4 max-w-4xl">
              <div className="col-span-2 flex">
                <input
                  id="taxable"
                  aria-describedby="selectAllBox"
                  name="taxable"
                  type="checkbox"
                  checked={values.taxable}
                  className="mt-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  disabled={true}
                />
                Taxable
              </div>
            </div> */}
            <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
              <SelectMenu
                handleInputChange={(e) => onPrimaryCategoryChanged(e)}
                name="primary_category"
                defaultValue={primaryCategoryOptions.filter(
                  (option) => option.value === product.primary_category
                )}
                options={primaryCategoryOptions}
                title={"Primary category"}
              />

              {values.primary_category ? (
                <SelectMenu
                  handleInputChange={(e) =>
                    handleInputChange(
                      convertToDefEventPara("sub_category_arr", e.target.value)
                    )
                  }
                  name="sub_category_arr"
                  value={values.sub_category_arr}
                  options={subCategoryOptions}
                  title={"Sub category"}
                />
              ) : null}
            </div>

            <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
              <div>
                <Input
                  label="Quickbooks Id"
                  name="quickbooks_id"
                  onChange={handleInputChange}
                  value={values.quickbooks_id}
                  labelOn={true}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mt-3 flex gap-4 max-w-4xl">
              {/* Image 1 starts */}
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="image1"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Image 1
                  </label>
                </div>
                {image1 ? (
                  <div>
                    <img src={image1} height="140" width="140" alt="New logo" />
                  </div>
                ) : product && values.image1 ? (
                  <img
                    src={values.image1}
                    alt={values.image1}
                    title={values.image1}
                    className="media-image rounded-md"
                    height="140"
                    width="140"
                  />
                ) : null}
                <ImportMediaDropzone
                  fileName="image1"
                  handleInputChange={handleInputChange}
                  setUploadedImage={setImage1}
                />
              </div>
              {/* Image 1 ends */}
              {/* Image 2 starts */}
              <div>
                <div className="flex space-x-2">
                  <label
                    htmlFor="image2"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Image 2
                  </label>
                </div>
                {image2 ? (
                  <div>
                    <img src={image2} height="140" width="140" alt="New logo" />
                  </div>
                ) : product && values.image2 ? (
                  <img
                    src={values.image2}
                    alt={values.image2}
                    title={values.image2}
                    className="media-image rounded-md"
                    height="140"
                    width="140"
                  />
                ) : null}
                <ImportMediaDropzone
                  fileName="image2"
                  handleInputChange={handleInputChange}
                  setUploadedImage={setImage2}
                />
              </div>
              {/* Image 2 ends */}
            </div>
          </div>

          <div className="sm:flex-no-wrap">
            <div className="my-auto flex items-center">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center mt-3"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
