import { useEffect } from "react";

import { connect } from "react-redux";

import * as actions from "../../../../store/actions/index";

import { withRouter } from "../../../../shared/utility";

import { useParams } from "react-router-dom";

import SimpleNotification from "../../../../components/SimpleNotification";
import { UserDetailsViewModel } from "../../../../viewmodels/setup/users/user/UserDetailsViewModel";
import UserDetailsForm from "../../../../components/forms/user/UserDetailsForm";

function UserDetails(props) {
  const { logout } = props;

  let { userId } = useParams();

  const {
    createUser,
    updateUser,
    getUser,
    showSimpleNotification,
    setShowSimpleNotification,
    user,
    loading,
    emptyModal,
  } = UserDetailsViewModel(logout, userId);

  const onStartDependencides = async function () {
    getUser();
  };

  useEffect(() => {
    if (userId) {
      onStartDependencides();
    }
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-4">
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />

      <div className="card-custom">
        {loading ? null : (
          <UserDetailsForm
            isEdit={userId}
            user={user}
            actionCall={(values) => {
              if (userId) {
                updateUser(values);
              } else {
                createUser(values);
              }
            }}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserDetails)
);
