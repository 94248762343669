import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { getLastItem, withRouter } from "../../../shared/utility";

import { useParams } from "react-router-dom";

import SimpleNotification from "../../../components/SimpleNotification";
import CustomerGroupForm from "../../../components/forms/customerGroups/CustomerGroupForm";
import { CustomerGroupDetailsViewModel } from "../../../viewmodels/customerGroups/group/CustomerGroupDetailsViewModel";
import CustomerGroupCustomersTable from "../../../components/forms/customerGroups/CustomerGroupCustomersTable";

function CustomerGroupDetails(props) {
  const { logout } = props;

  let { groupId } = useParams();

  const {
    createGroup,
    updateGroup,
    getGroup,
    showSimpleNotification,
    setShowSimpleNotification,
    group,
    loading,
    emptyModal,
  } = CustomerGroupDetailsViewModel(logout, groupId);

  const onStartDependencides = async function () {
    // getAllergensForDropdown();
    // getUnitOfMeasuresForDropdown();
    getGroup();
  };

  useEffect(() => {
    if (groupId) {
      onStartDependencides();
    }
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-4">
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />

      <div className="card-custom">
        <CustomerGroupForm
          isEdit={groupId}
          group={group}
          actionCall={(values) => {
            if (groupId) {
              updateGroup(values);
            } else {
              createGroup(values);
            }
          }}
        />
      </div>
      {groupId ? (
        <CustomerGroupCustomersTable customers={group.customers} />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerGroupDetails)
);
