import DatePickerInput from "../../controls/DatePickerInput";
import Input from "../../controls/Input";

export default function OrderDetails(props) {
  const { orderDetails, errors, handleInputChange, onCustomerRelatedChange } =
    props;

  return (
    <div className="mt-6 px-1 sm:px-6 space-y-4">
      <div className="text-center">
        <h3>Summary</h3>
      </div>
      <div className="mt-2 space-y-3">
        <Input
          label="PO #"
          name="po_number"
          onChange={(e) => {
            handleInputChange(e);
            onCustomerRelatedChange("po_number", e.target.value);
          }}
          value={orderDetails.po_number}
          labelOn={true}
          type="text"
          error={errors.po_number}
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
        />

        <DatePickerInput
          label="Order Date"
          name="order_date"
          onChange={(e) => {
            handleInputChange(e);
            onCustomerRelatedChange("order_date", e.target.value);
          }}
          value={orderDetails.order_date}
          labelOn={true}
          minDate={new Date()}
          dateFormat="LLL dd, YYY"
          type="text"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          error={errors.order_date}
        />

        <DatePickerInput
          label="Expected Date"
          name="expected_date"
          onChange={(e) => {
            handleInputChange(e);
            onCustomerRelatedChange("expected_date", e.target.value);
          }}
          value={orderDetails.expected_date}
          labelOn={true}
          minDate={new Date()}
          dateFormat="LLL dd, YYY"
          type="text"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
          error={errors.expected_date}
        />
      </div>
      <div className="border border-gray-400 p-1">
        <h3>Shipping address</h3>
        <div className="mt-3 grid grid-cols-1 gap-4 max-w-4xl">
          <div>
            <Input
              label="Billing Address Line 1"
              name="line1"
              value={orderDetails.line1}
              labelOn={true}
              disabled={true}
            />
          </div>
          <div>
            <Input
              label="Billing Address Line 2"
              name="line2"
              value={orderDetails.line2}
              labelOn={true}
              disabled={true}
            />
          </div>
        </div>
        <div className="mt-3 grid grid-cols-2 gap-4 max-w-4xl">
          <div>
            <Input
              label="City"
              name="city"
              value={orderDetails.city}
              labelOn={true}
              disabled={true}
            />
          </div>
          <div>
            <Input
              label="Province"
              name="province"
              value={orderDetails.province}
              labelOn={true}
              disabled={true}
            />
          </div>
          <div>
            <Input
              label="State"
              name="state"
              value={orderDetails.state}
              labelOn={true}
              disabled={true}
            />
          </div>
          <div>
            <Input
              label="Country"
              name="country_code"
              value={orderDetails.country_code}
              labelOn={true}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
