import { axiosApiInstance, axiosStorageInstance } from "../axios";

export async function makePostApiCall(endPoint, payload, contentType) {
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("authToken"),
      "content-type": contentType ? contentType : "application/json",
    },
  };

  const user_id = localStorage.getItem("id") * 1;

  const newPayload = {
    ...payload,
    user_id: user_id,
  };

  return axiosApiInstance
    .post(endPoint, newPayload, config)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch((err) => {
      console.log("err", err.response);
      const emptyData = {
        data: {
          title: "Error",
          subtitle: "We could not complete the action.",
          errorStatus: err.response.status,
        },
      };

      if (err.response) {
        if (err.response.data) {
          return {
            success: false,
            data: err.response.data,
            errorStatus: err.response.status,
          };
        }
        return emptyData;
      } else {
        return {
          success: false,
          data: emptyData,
          errorStatus: err.response.status,
        };
      }
    });
}

export async function makePutApiCall(endPoint, payload, contentType) {
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("authToken"),
      "content-type": contentType ? contentType : "application/json",
    },
  };

  const user_id = localStorage.getItem("id") * 1;

  const newPayload = {
    ...payload,
    user_id: user_id,
  };

  return axiosApiInstance
    .put(endPoint, newPayload, config)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch((err) => {
      console.log("err", err.response);
      const emptyData = {
        data: {
          title: "Error",
          subtitle: "We could not complete the action.",
          errorStatus: err.response.status,
        },
      };

      if (err.response) {
        if (err.response.data) {
          return {
            success: false,
            data: err.response.data,
            errorStatus: err.response.status,
          };
        }
        return emptyData;
      } else {
        return {
          success: false,
          data: emptyData,
          errorStatus: err.response.status,
        };
      }
    });
}

export async function makeGetApiCall(endPoint) {
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };

  return axiosApiInstance
    .get(endPoint, config)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch((err) => {
      console.log("err", err.response);
      const emptyData = {
        data: {
          title: "Error",
          subtitle: "We could not complete the action.",
          errorStatus: err.response.status,
        },
      };

      if (err.response) {
        if (err.response.data) {
          return {
            success: false,
            data: err.response.data,
            errorStatus: err.response.status,
          };
        }
        return emptyData;
      } else {
        return {
          success: false,
          data: emptyData,
          errorStatus: err.response.status,
        };
      }
    });
}

export async function makeGetCall(endPoint) {
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };

  return axiosStorageInstance
    .get(endPoint, config)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      };
    })
    .catch((err) => {
      console.log("err", err.response);
      const emptyData = {
        data: {
          title: "Error",
          subtitle: "We could not complete the action.",
          errorStatus: err.response.status,
        },
      };

      if (err.response) {
        if (err.response.data) {
          return {
            success: false,
            data: err.response.data,
            errorStatus: err.response.status,
          };
        }
        return emptyData;
      } else {
        return {
          success: false,
          data: emptyData,
          errorStatus: err.response.status,
        };
      }
    });
}
