const stats = [
  {
    name: "Total Orders",
    value: "$0",
    change: "+0%",
    changeType: "positive",
  },
  {
    name: "Total Shipped Orders",
    value: "$0",
    change: "+0%",
    changeType: "negative",
  },
  {
    name: "Produced Products",
    value: "$0",
    change: "+0%",
    changeType: "positive",
  },
  {
    name: "Packaged Products",
    value: "$0",
    change: "+0%",
    changeType: "negative",
  },
  {
    name: "Low In Stock",
    value: "$0",
    change: "+0%",
    changeType: "negative",
  },
  {
    name: "Received Amount",
    value: "$0",
    change: "+0%",
    changeType: "negative",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SummaryCards() {
  return (
    <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-6">
      {stats.map((stat) => (
        <div
          key={stat.name}
          className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-3 sm:px-6 xl:px-2"
        >
          <dt className="text-sm font-medium leading-6 text-gray-500">
            {stat.name}
          </dt>
          <dd
            className={classNames(
              stat.changeType === "negative"
                ? "text-rose-600"
                : "text-gray-700",
              "text-xs font-medium"
            )}
          >
            {stat.change}
          </dd>
          <dd className="w-full flex-none text-xl font-medium leading-10 tracking-tight text-gray-900">
            {stat.value}
          </dd>
        </div>
      ))}
    </dl>
  );
}
