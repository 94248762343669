import AsyncSelect from "react-select/async";

import Input from "../../controls/Input";
import AddProductToOrderForm from "../orders/modals/AddProductToOrderForm";

export default function ItemsScreen(props) {
  const {
    localCart,
    onRemoveProductFromCart,
    onProductQuantityChanged,
    isEdit,
    setEmptyModelOpen,
    addProductToOrder,
    setAsyncSelectValue,
    asyncSelectValue,
    loadOptions,
  } = props;

  return (
    <div className="mt-2 flex-1 sm:px-6 space-y-4">
      {isEdit ? (
        <div className="content-end">
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            options={[]}
            className="w-full"
            onChange={(e) => {
              setEmptyModelOpen({
                childComponent: (
                  <AddProductToOrderForm
                    product={e}
                    confirmAction={addProductToOrder}
                    isEdit={false}
                    setOpen={() => {
                      setEmptyModelOpen({
                        open: false,
                      });
                      setAsyncSelectValue({ value: -1, label: "" });
                    }}
                  />
                ),
                open: true,
              });
            }}
            value={asyncSelectValue}
          />
        </div>
      ) : null}
      {localCart.length > 0 ? (
        localCart.map((product, index) => (
          <div className="card-custom p-3">
            <div className="flex justify-between">
              <div className="">
                <p className="font-bold text-sm sm:text-xl">{product.name}</p>
                <p className="text-md sm:text-xl">${product.unit_price}</p>
              </div>
              <div>
                <button
                  className="bg-gray-500 text-white py-1.5 px-3 rounded-md my-auto"
                  type="button"
                  onClick={() => onRemoveProductFromCart(product, index)}
                >
                  X
                </button>
              </div>
            </div>
            <div className="flex">
              <div>
                <img
                  src={product.image1}
                  alt={product.image1}
                  title={product.image1}
                  className="media-image rounded-md my-auto mx-auto w-24 h-24"
                />
              </div>
              <div className="flex">
                <div className="flex">
                  <div className="flex p-2 gap-1 mx-auto">
                    <button
                      className="bg-indigo-500 text-white py-1.5 px-3 rounded-md my-auto"
                      type="button"
                      onClick={() =>
                        onProductQuantityChanged(
                          "quantity",
                          parseFloat(product.quantity) - 1,
                          index
                        )
                      }
                      disabled={product.quantity === 0}
                    >
                      -
                    </button>
                    <Input
                      onChange={(e) =>
                        onProductQuantityChanged(
                          "quantity",
                          e.target.value,
                          index
                        )
                      }
                      value={product.quantity}
                      labelOn={false}
                      parentClass="my-auto"
                      className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none w-14 sm:text-sm sm:max-w-xs md:max-w-full sm:w-24 text-center"
                      min="0"
                      type="number"
                    />
                    <button
                      className="bg-indigo-500 text-white py-1.5 px-3 rounded-md my-auto"
                      type="button"
                      onClick={() =>
                        onProductQuantityChanged(
                          "quantity",
                          parseFloat(product.quantity) + 1,
                          index
                        )
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 mt-1 gap-2">
              <Input
                name="discount"
                onChange={(e) =>
                  onProductQuantityChanged(e.target.name, e.target.value, index)
                }
                label="Discount"
                value={product.discount}
                labelOn={true}
                parentClass="my-auto text-center"
                className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none w-14 sm:text-sm sm:max-w-xs md:max-w-full sm:w-24 text-center"
                min="0"
                type="number"
              />
              <Input
                name="price"
                onChange={(e) =>
                  onProductQuantityChanged(e.target.name, e.target.value, index)
                }
                label="Price"
                value={product.price}
                labelOn={true}
                parentClass="my-auto text-center"
                className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none w-14 sm:text-sm sm:max-w-xs md:max-w-full sm:w-24 text-center"
                min="0"
                type="number"
              />
              <Input
                name="tax"
                disabled={true}
                label="Taxes"
                value={product.tax}
                labelOn={true}
                parentClass="my-auto text-center"
                className="block w-full shadow-sm py-2 px-3 focus:outline-none w-14 sm:text-sm sm:max-w-xs md:max-w-full sm:w-24 text-center"
                min="0"
                type="number"
              />
              <Input
                name="total"
                disabled={true}
                label="Total"
                value={product.total}
                labelOn={true}
                parentClass="my-auto text-center"
                className="block w-full shadow-sm py-2 px-3 focus:outline-none w-14 sm:text-sm sm:max-w-xs md:max-w-full sm:w-24 text-center"
                min="0"
                type="number"
              />
            </div>
            <div className="mt-1">
              <Input
                name="notes"
                onChange={(e) =>
                  onProductQuantityChanged(e.target.name, e.target.value, index)
                }
                label="Notes"
                value={product.notes}
                labelOn={true}
                parentClass="my-auto"
                className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none w-14 sm:text-sm sm:max-w-xs md:max-w-full sm:w-24"
                min="0"
                type="text"
              />
            </div>
          </div>
        ))
      ) : (
        <div>
          <p>Please add items to your cart</p>
        </div>
      )}
    </div>
  );
}
