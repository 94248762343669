import { useEffect } from "react";

import Input from "../../controls/Input";
import {
  convertToDefEventPara,
  updateObjectInArray,
} from "../../../shared/utility";

export default function ProductPriceList(props) {
  const { priceList, actionCall, setPriceList } = props;

  const handleSubmit = (e) => {
    e.preventDefault();
    actionCall();
  };

  useEffect(() => {
    if (priceList) {
    }
  }, []);

  const handleItemChange = (name, newValue, index) => {
    const copyPriceList = [...priceList];
    const singleItem = copyPriceList[index];

    var singleItemBeingEdited = {
      ...singleItem,
      [name]: newValue,
    };

    if (name === "price") {
      singleItemBeingEdited = {
        ...singleItemBeingEdited,
        availability: true,
      };
    }

    var payload = {
      index: index,
      item: singleItemBeingEdited,
    };
    const newPriceList = updateObjectInArray(copyPriceList, payload);
    setPriceList(newPriceList);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="p-4">
        <div className="space-y-3">
          <div>
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Availability
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {priceList.map((group, index) => (
                  <tr key={group.id}>
                    <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {group.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <input
                        id="availability"
                        aria-describedby="selectAllBox"
                        name="availability"
                        type="checkbox"
                        checked={group.availability}
                        className="mt-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        onClick={(e) =>
                          handleItemChange(
                            "availability",
                            e.target.checked,
                            index
                          )
                        }
                      />
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <Input
                        name="price"
                        onChange={(e) =>
                          handleItemChange("price", e.target.value, index)
                        }
                        value={group.price}
                        labelOn={false}
                        className="block w-16 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="sm:flex-no-wrap">
            <div className="my-auto flex items-center">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center mt-3"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
