import React, { useEffect, useState } from "react";

import { useForm } from "../useForm";

import {
  orderStatusOptions,
  updateObjectInArray,
} from "../../../shared/utility";
import { classNames } from "primereact/utils";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import ItemsScreen from "../cart/ItemsScreen";
import OrderSummary from "../cart/OrderSummary";
import OrderDetails from "../cart/OrderDetails";
import DeleteConfirmationForm from "../DeleteConfirmationForm";

const tabs = [
  { name: "Items", href: "#" },
  { name: "Summary", href: "#" },
];

const items = [{ name: "Invoice" }, { name: "Reorder" }, { name: "Delete" }];

const initialFValues = {
  id: 0,
  customer: "",
  customer_id: 0,
  subtotal_dollar_amount: 0,
  total_dollar_amount: 0,
  order_date: new Date(),
  order_items: [],
};

export default function MobileOrderDetailsForm(props) {
  const {
    customers,
    getMyProducts,
    employeeOptions,
    isEdit,
    loading,
    order,
    setEmptyModelOpen,
    showErrorModal,
    updateOrderApiCall,
    createOrderApiCall,
    onUpdateOrderItem,
    deleteOrderItemApiCall,
    addProductToOrderApiCall,
    printOrder,
    createEstimate,
    openAddProductModal,
  } = props;

  const [currentTab, setCurrentTab] = useState(0);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.customer = null;
    if (values.customer === "") {
      temp.customer = "Please fill out field.";
    }

    temp.expected_date = null;
    if (values.expected_date === "") {
      temp.expected_date = "Please fill out field.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  useEffect(() => {
    if (order) {
      const orderCopy = {
        ...order,
        status: orderStatusOptions.filter(
          (option) => option.value === order.status
        )[0],
      };

      const itemsCopy = orderCopy.order_items.map((item) => {
        return {
          id: item.id,
          quantity: item.quantity,
          total: item.total_dollar_amount,
          name: item.product_name,
          shipped_amount: item.shipped_amount,
          product_id: item.product_id,
          unit_price: item.price,
          image1: item.image1,
          image2: item.image2,
          price: item.price,
          discount: 0,
          tax: 0,
          plu: item.plu,
          description: item.description,
          selling_unit_of_measure: item.selling_unit_of_measure,
          quickbooks_id: item.quickbooks_id,
          shippingItems: item.shippingItems,
        };
      });

      setValues({ ...orderCopy, order_items: itemsCopy });
    }
  }, [order]);

  const [asyncSelectValue, setAsyncSelectValue] = useState({
    value: -1,
    label: "",
  });

  const loadOptions = async function (input, callback) {
    if (input.length > 2) {
      const result = await getMyProducts(input);
      callback(result);
    }
  };

  function findIndexForProduct(product_id) {
    return values.order_items.findIndex(
      (element) => element.product_id === product_id
    );
  }

  const addProductToOrder = async function (addedProductValues) {
    setAsyncSelectValue({ value: -1, label: "" });
    const productToAdd = {
      id: -1,
      product_name: addedProductValues.product_name,
      product_id: addedProductValues.product_id,
      quantity: addedProductValues.quantity,
      individual_unit_size: addedProductValues.individual_unit_size,
      selling_unit_of_measure: addedProductValues.selling_unit_of_measure,
      plu: addedProductValues.plu,
      description: addedProductValues.description,
      unit_price: addedProductValues.unit_price,
      group_price: addedProductValues.group_price,
      quickbooks_id: addedProductValues.quickbooks_id,
    };

    const productIndex = findIndexForProduct(productToAdd.product_id);
    if (productIndex > -1) {
      showErrorModal("Product already in order, please edit it.");
      return;
    }

    let callResult = { success: false };
    let newOrderProducts = [];
    if (isEdit) {
      callResult = await addProductToOrderApiCall(values.id, productToAdd);
      newOrderProducts = [...values.order_items, callResult.data];
    } else {
      newOrderProducts = [...values.order_items, productToAdd];
    }

    if (callResult || !isEdit) {
      setValues({
        ...values,
        order_items: newOrderProducts,
      });
      setEmptyModelOpen({
        childComponent: null,
        open: false,
      });
    }
  };

  const onProductQuantityChanged = (name, newValue, index) => {
    const copyProducts = [...values.order_items];
    const singleItem = copyProducts[index];

    var total = singleItem.total;
    if (name === "price") {
      total = newValue * singleItem.quantity;
    } else if (name === "quantity") {
      total = newValue * singleItem.price;
    }

    const singleItemBeingEdited = {
      ...singleItem,
      [name]: newValue,
      total: total,
    };
    var payload = {
      index: index,
      item: singleItemBeingEdited,
    };
    const newProducts = updateObjectInArray(copyProducts, payload);

    setValues({ ...values, order_items: newProducts });
  };

  const makeDeleteOrderItemApiCall = async function (itemId) {
    var onItemDeleted = { success: false };
    if (isEdit) {
      onItemDeleted = await deleteOrderItemApiCall(itemId);
    }

    if (onItemDeleted.success || !isEdit) {
      const filteredItems = values.order_items.filter(
        (item) => item.id !== itemId
      );
      setValues({ ...values, order_items: filteredItems });
    }
    setEmptyModelOpen({
      childComponent: null,
      open: false,
    });
  };

  const askDeleteOrderItem = function (product, index) {
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => makeDeleteOrderItemApiCall(product.id)}
          message={`Are you sure you want to ${product.name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const renderComponent = (index) => {
    switch (index) {
      case 0:
        return (
          <div key={0}>
            <ItemsScreen
              localCart={values.order_items}
              isEdit={true}
              onRemoveProductFromCart={askDeleteOrderItem}
              onProductQuantityChanged={onProductQuantityChanged}
              setEmptyModelOpen={setEmptyModelOpen}
              addProductToOrder={addProductToOrder}
              setAsyncSelectValue={setAsyncSelectValue}
              asyncSelectValue={asyncSelectValue}
              loadOptions={loadOptions}
            />
          </div>
        );

      case 1:
        return (
          <div key={1} className="-mt-2 bg-white rounded-lg">
            <OrderSummary localCart={values.order_items} />
            <OrderDetails
              orderDetails={values}
              errors={errors}
              handleInputChange={handleInputChange}
              onCustomerRelatedChange={() => {}}
            />
          </div>
        );

      case 2:
        return (
          <div key={2} className="-mt-2 bg-white rounded-lg">
            Notes coming soon
          </div>
        );

      default:
        return (
          <div key={1000} className="-mt-2 bg-white">
            Could not load page, please try again
          </div>
        );
    }
  };

  return (
    <div className="mt-5 h-full">
      <div className="flex justify-between">
        <h1 className="text-xl font-semibold leading-6 text-gray-900">
          Editing Order {order ? order.po_number : null}
        </h1>

        <div className="inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            onClick={() => updateOrderApiCall(values)}
          >
            Save changes
          </button>
          <Menu as="div" className="relative -ml-px block">
            <MenuButton className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
              <span className="sr-only">Open options</span>
              <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
            </MenuButton>
            <MenuItems
              transition
              className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1 divide-y divider-gray-500">
                {items.map((item) => (
                  <MenuItem key={item.name}>
                    <button
                      href={item.href}
                      className="block px-4 py-3 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 w-full"
                    >
                      {item.name}
                    </button>
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Menu>
        </div>
      </div>
      <div className="mt-5">
        <div className="sm:block">
          <nav
            className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
            aria-label="Tabs"
          >
            {tabs.map((tab, index) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  index === currentTab
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  index === 0 ? "rounded-l-lg" : "",
                  index === tabs.length - 1 ? "rounded-r-lg" : "",
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                )}
                aria-current={index === currentTab ? "page" : undefined}
                onClick={() => setCurrentTab(index)}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    index === currentTab ? "bg-indigo-500" : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </a>
            ))}
          </nav>
        </div>

        <div className="sm:col-span-9 rounded-3xl pb-2">
          {tabs.map((item, index) =>
            index === currentTab ? renderComponent(index) : null
          )}
        </div>
      </div>
    </div>
  );
}
