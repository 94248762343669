import React, { useState } from "react";

import Input from "../../controls/Input";
import SelectMenu from "../../controls/SelectMenu";
import DatePickerInput from "../../controls/DatePickerInput";
import { convertToDefEventPara } from "../../../shared/utility";

export default function CreateNewOrderForm(props) {
  const {
    breadCrumbs,
    isEdit,
    loading,
    setEmptyModelOpen,
    showErrorModal,
    createOrderApiCall,
    setSlideOver,
    customerOptions,
    primaryCategories,
    subCategories,
    products,
    onCustomerSelected,
    primaryCategorySelected,
    subCategorySelected,
    handleSubmit,
    values,
    onProductQuantityChanged,
    handleInputChange,
    errors,
  } = props;

  // grid grid-cols-3 gap-3
  return (
    <div className="h-full">
      <div>
        <div className="space-y-6">
          {/* The categories that customer group has products assigned to in price list */}
          <div>
            {primaryCategories && !subCategories && !products ? (
              primaryCategories.length > 0 ? (
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
                  <div className="col-span-2 sm:col-span-4">
                    <p className="">Please select a category</p>
                  </div>
                  {primaryCategories.map((cat, index) => (
                    <div
                      className="border border-gray-400 p-5 text-center cursor-pointer"
                      onClick={() => primaryCategorySelected(cat)}
                    >
                      <div className="min-h-32 sm:min-h-40">
                        <img
                          src={cat.image1}
                          alt={cat.image1}
                          title={cat.image1}
                          className="media-image rounded-md my-auto mx-auto"
                          height="140"
                          width="140"
                        />
                      </div>
                      <p className="">{cat.name}</p>
                    </div>
                  ))}
                </div>
              ) : (
                "It seems no categories or products are assigned"
              )
            ) : null}
            {subCategories && !products ? (
              subCategories.length > 0 ? (
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
                  <div className="col-span-2 sm:col-span-4">
                    <p className="">Please select a sub category</p>
                  </div>
                  {subCategories.map((cat, index) => (
                    <div
                      className="border border-gray-400 p-5 text-center cursor-pointer"
                      onClick={() => subCategorySelected(cat)}
                    >
                      <div className="min-h-32 sm:min-h-40">
                        <img
                          src={cat.image1}
                          alt={cat.image1}
                          title={cat.image1}
                          className="media-image rounded-md my-auto mx-auto"
                          height="140"
                          width="140"
                        />
                      </div>
                      <p className="">{cat.name}</p>
                    </div>
                  ))}
                </div>
              ) : (
                "It seems no sub category or products are assigned"
              )
            ) : null}
            {products ? (
              products.length > 0 ? (
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
                  <div className="col-span-2 sm:col-span-4">
                    <p className="">Please select product quantities</p>
                  </div>
                  {products.map((product, index) => (
                    <div className="flex flex-col justify-between border border-gray-400 text-center">
                      <div className="">
                        <div className="min-h-32 sm:min-h-40 p-5">
                          <img
                            src={product.image1}
                            alt={product.image1}
                            title={product.image1}
                            className="media-image rounded-md my-auto mx-auto"
                            height="140"
                            width="140"
                          />
                        </div>
                        <p className="">{product.name}</p>
                      </div>
                      <div className="flex p-2 gap-1 mx-auto">
                        <button
                          className="bg-indigo-500 text-white py-1.5 px-3 rounded-md"
                          type="button"
                          onClick={() =>
                            onProductQuantityChanged(
                              null,
                              product,
                              index,
                              false,
                              true
                            )
                          }
                          disabled={product.quantity === 0}
                        >
                          -
                        </button>
                        <Input
                          onChange={(e) =>
                            onProductQuantityChanged(
                              e.target.value,
                              product,
                              index,
                              false,
                              false
                            )
                          }
                          value={product.quantity}
                          labelOn={false}
                          className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none sm:text-sm sm:max-w-xs md:max-w-full sm:w-24 text-center"
                          min="0"
                          type="number"
                        />
                        <button
                          className="bg-indigo-500 text-white py-1.5 px-3 rounded-md"
                          type="button"
                          onClick={() =>
                            onProductQuantityChanged(
                              null,
                              product,
                              index,
                              true,
                              false
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                "It seems no products are assigned"
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
