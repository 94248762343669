import { useEffect, useState } from "react";
import { CreateOrderViewModel } from "../../viewmodels/orders/CreateOrderViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SlideOver from "../../components/slideOver/SlideOver";
import SimpleNotification from "../../components/SimpleNotification";
import CreateNewOrderForm from "../../components/forms/orders/CreateNewOrderForm";
import BreadCrumbs from "../../components/controls/BreadCrumbs";

function CreateOrder(props) {
  const { cartCustomer, modifyCart, logout, setCartCustomer } = props;
  const {
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    createOrderApiCall,
    customerOptions,
    breadCrumbs,
    primaryCategories,
    subCategories,
    products,
    onCustomerSelected,
    primaryCategorySelected,
    subCategorySelected,
    handleSubmit,
    values,
    onProductQuantityChanged,
    handleInputChange,
    errors,
    openChangeCustomerModal,
    getPrimaryCategoriesForCustomerGroup,
  } = CreateOrderViewModel(cartCustomer, modifyCart, logout, setCartCustomer);

  useState(() => {
    if (cartCustomer) {
      getPrimaryCategoriesForCustomerGroup(
        cartCustomer.company_name,
        cartCustomer.customer_group_id
      );
    }
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8 space-y-4">
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />

      <BreadCrumbs pgs={breadCrumbs} />
      <div className="mt-2">
        <div className="sm:items-center">
          <div className="sm:flex justify-between">
            <h1 className="text-xl font-semibold leading-6 text-gray-900 my-auto">
              {cartCustomer.company_name ? (
                <p>
                  <span className="text-gray-500">Creating order for</span>{" "}
                  {cartCustomer.company_name}
                </p>
              ) : (
                "Create Order"
              )}
            </h1>
            <button
              type="button"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-main-purple hover:bg-indigo-700 items-center mt-3"
              onClick={openChangeCustomerModal}
            >
              Choose Customer
            </button>
          </div>
          <div className="mt-5">
            <CreateNewOrderForm
              breadCrumbs={breadCrumbs}
              isEdit={false}
              loading={loading}
              setEmptyModelOpen={setEmptyModelOpen}
              showErrorModal={showErrorModal}
              createOrderApiCall={createOrderApiCall}
              setSlideOver={setSlideOver}
              customerOptions={customerOptions}
              primaryCategories={primaryCategories}
              subCategories={subCategories}
              products={products}
              onCustomerSelected={onCustomerSelected}
              primaryCategorySelected={primaryCategorySelected}
              subCategorySelected={subCategorySelected}
              handleSubmit={handleSubmit}
              values={values}
              onProductQuantityChanged={onProductQuantityChanged}
              handleInputChange={handleInputChange}
              errors={errors}
            />
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cartCustomer: state.cart.cartCustomer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    modifyCart: (changedQuantity, newItem, isPlus, isMinus) =>
      dispatch(actions.modifyCart(changedQuantity, newItem, isPlus, isMinus)),
    setCartCustomer: (customer) => dispatch(actions.setCartCustomer(customer)),
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateOrder)
);
