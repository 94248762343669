import { useEffect, useState } from "react";
import { ProductDetailsViewModel } from "../../../viewmodels/products/product/ProductDetailsViewModel";

import { useLocation } from "react-router-dom";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { classNames, getLastItem, withRouter } from "../../../shared/utility";

import SimpleNotification from "../../../components/SimpleNotification";
import ProductDetailsForm from "../../../components/forms/products/ProductDetailsForm";
import ProductPriceList from "../../../components/forms/products/ProductPriceList";
import { useForm } from "../../../components/forms/useForm";

const tabs = [
  { name: "Product Details", href: "#" },
  { name: "Price list", href: "#" },
];

const initialFValues = {
  priceList: [],
};

function ProductDetails(props) {
  const { logout } = props;

  const [currentTab, setCurrentTab] = useState(0);

  const { pathname } = useLocation();
  const productId = getLastItem(pathname);

  const {
    primaryCategoryOptions,
    getPrimaryCategoriesForDropdown,
    updateProduct,
    getProduct,
    showSimpleNotification,
    setShowSimpleNotification,
    product,
    loading,
    emptyModal,
    getUsersForDropdown,
    usersOptions,
    getSubCategoriesByPrimary,
    subCategoryOptions,
    getPriceListForProduct,
    priceList,
    setPriceList,
  } = ProductDetailsViewModel(logout, productId);

  const onStartDependencides = async function () {
    let productsDropdown = await getPriceListForProduct();
    productsDropdown = await getPrimaryCategoriesForDropdown();
    let usersDropdown = await getUsersForDropdown();
    getProduct();
  };

  useEffect(() => {
    onStartDependencides();
  }, []);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProduct(values);
  };

  const renderComponent = (index) => {
    switch (index) {
      case 0:
        return (
          <div key={0} className="-mt-2 bg-white rounded-lg">
            {loading ? (
              "loading"
            ) : (
              <ProductDetailsForm
                primaryCategoryOptions={primaryCategoryOptions}
                subCategoryOptions={subCategoryOptions}
                getSubCategoriesByPrimary={getSubCategoriesByPrimary}
                product={product}
                actionCall={updateProduct}
                usersOptions={usersOptions}
                setValues={setValues}
                values={values}
                handleSubmit={handleSubmit}
                handleInputChange={handleInputChange}
              />
            )}
          </div>
        );

      case 1:
        return (
          <div key={1} className="-mt-2 bg-white rounded-lg">
            {loading ? (
              "loading"
            ) : (
              <ProductPriceList
                actionCall={() => updateProduct(values)}
                priceList={priceList}
                setPriceList={setPriceList}
              />
            )}
          </div>
        );

      default:
        return (
          <div key={1000} className="-mt-2 bg-white">
            Could not load page, please try again
          </div>
        );
    }
  };

  return (
    <div>
      <SimpleNotification
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <main>
        {/* max-w-7xl mx-auto */}
        <div className="py-6 sm:px-6 lg:px-8">
          {/* Content starts here */}

          {/* tab here */}
          <div className="hide-for-print">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full  border-gray-300 rounded-md"
                defaultValue={tabs[currentTab].name}
              >
                {tabs.map((tab, index) => (
                  <option key={index}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav
                className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                aria-label="Tabs"
              >
                {tabs.map((tab, index) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      index === currentTab
                        ? "text-gray-900"
                        : "text-gray-500 hover:text-gray-700",
                      index === 0 ? "rounded-l-lg" : "",
                      index === tabs.length - 1 ? "rounded-r-lg" : "",
                      "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                    )}
                    aria-current={index === currentTab ? "page" : undefined}
                    onClick={() => setCurrentTab(index)}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        index === currentTab
                          ? "bg-indigo-500"
                          : "bg-transparent",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </a>
                ))}
              </nav>
            </div>
          </div>

          {/* {tab content starts below} */}
          <div className="px-4 py-6 sm:px-0 rounded-3xl">
            <div className="sm:col-span-9 rounded-3xl">
              {tabs.map((item, index) =>
                index === currentTab ? renderComponent(index) : null
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetails)
);
