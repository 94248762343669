import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";
import { SimpleNotificationType } from "../../components/SimpleNotification";

export function DashboardViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });

  const [info1Loading, setInfo1Loading] = useState(false);
  const [info2Loading, setInfo2Loading] = useState(false);
  const [info3Loading, setInfo3Loading] = useState(false);
  const [info4Loading, setInfo4Loading] = useState(false);
  const [info5Loading, setInfo5Loading] = useState(false);
  const [info6Loading, setInfo6Loading] = useState(false);
  const [info7Loading, setInfo7Loading] = useState(false);

  const [info1, setInfo1] = useState({ data: [] });
  const [info2, setInfo2] = useState({ data: [] });
  const [info3, setInfo3] = useState({ data: [] });
  const [info4, setInfo4] = useState({ data: [] });
  const [info5, setInfo5] = useState({ data: [] });
  const [info6, setInfo6] = useState({ data: [] });
  const [info7, setInfo7] = useState({ data: [] });

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });

  const getInfo1 = async function (search, page) {
    setInfo1Loading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall("orders/dashboard", payload);

    if (callResult.success) {
      setInfo1(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setInfo1Loading(false);
  };

  const getInfo2 = async function (search, page) {
    setInfo2Loading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall("shipments/dashboard", payload);

    if (callResult.success) {
      setInfo2(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setInfo2Loading(false);
  };

  const getInfo3 = async function (search, page) {
    setInfo3Loading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall("packaged/overTwoMonths", payload);

    if (callResult.success) {
      setInfo3(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setInfo3Loading(false);
  };

  const getInfo4 = async function (search, page) {
    setInfo4Loading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall("manufacture/dashboard", payload);

    if (callResult.success) {
      setInfo4(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setInfo4Loading(false);
  };

  const getInfo5 = async function (search, page) {
    setInfo5Loading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall("packaged/dashboard", payload);

    if (callResult.success) {
      setInfo5(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setInfo5Loading(false);
  };

  const getInfo6 = async function (search, page) {
    setInfo6Loading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(
      "rawMaterialLowInventory/dashboard",
      payload
    );

    if (callResult.success) {
      setInfo6(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setInfo6Loading(false);
  };

  const getInfo7 = async function (search, page) {
    setInfo7Loading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(
      "rawMaterialBelowThreshold/dashboard",
      payload
    );

    if (callResult.success) {
      setInfo7(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setInfo7Loading(false);
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  return {
    getInfo1,
    getInfo2,
    getInfo3,
    getInfo4,
    getInfo5,
    getInfo6,
    getInfo7,
    info1,
    info2,
    info3,
    info4,
    info5,
    info6,
    info7,
    info1Loading,
    info2Loading,
    info3Loading,
    info4Loading,
    info5Loading,
    info6Loading,
    info7Loading,
  };
}
