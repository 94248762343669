import { useState } from "react";

import { makePostApiCall, makePutApiCall } from "../../../shared/makeApiCall";

import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";
import { SimpleNotificationType } from "../../../components/SimpleNotification";

export function CustomerDetailsViewModel(logout, customerId) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [customerGroupOptions, setCustomerGroupOptions] = useState(null);
  const [usersOptions, setUsersOptions] = useState(null);

  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: SimpleNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getCustomer = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`customers/${customerId}`, payload);
    if (callResult.success) {
      setCustomer(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  const updateCustomer = async function (values) {
    let callResult = await makePutApiCall(`customers/${customer.id}`, values);

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Customer updated",
        type: SimpleNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  // const deleteCustomer = async function (id, index) {
  //   setLoading(true);

  //   let callResult = await makePostApiCall(`deleteCustomer/${id}`, {});
  //   setLoading(false);
  //   if (callResult.success) {
  //     setEmptyModelOpen({
  //       open: false,
  //       childComponent: null,
  //     });
  //     setShowSimpleNotification({
  //       show: true,
  //       title: callResult.data.message,
  //       type: SimpleNotificationType.Success,
  //     });
  //   } else {
  //     if (callResult.errorStatus === 401) {
  //       logout();
  //     }
  //   }
  // };

  // const askDeleteCustomer = function (customer, index) {
  //   setEmptyModelOpen({
  //     childComponent: (
  //       <DeleteConfirmationForm
  //         buttonMessage="Delete"
  //         confirmAction={() => deleteCustomer(customer.id, index)}
  //         message={`Are you sure you want to delete ${customer.name}?`}
  //         setOpen={setEmptyModelOpen}
  //       />
  //     ),
  //     open: true,
  //   });
  // };

  const getCustomerGroupsForDropdown = async function () {
    setLoading(true);

    let callResult = await makePostApiCall(`getCustomersGroupsForDropdown`);
    setLoading(false);
    if (callResult.success) {
      setCustomerGroupOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getUsersForDropdown = async function () {
    setLoading(true);

    let callResult = await makePostApiCall(`usersForDropdown`);
    setLoading(false);
    if (callResult.success) {
      setUsersOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    customerGroupOptions,
    getCustomerGroupsForDropdown,
    updateCustomer,
    getCustomer,
    slideOver,
    setSlideOver,
    showSimpleNotification,
    setShowSimpleNotification,
    customer,
    loading,
    emptyModal,
    getUsersForDropdown,
    usersOptions,
  };
}
