import { updateObjectInArray } from "../../shared/utility";
import * as actionTypes from "./actionTypes";

function findIndexForCartItem(cart, id) {
  return cart.findIndex((element) => element.id === id);
}

export const dispatchCart = (cart) => {
  localStorage.setItem(`cart`, JSON.stringify(cart));
  return {
    type: actionTypes.SET_CART,
    cart: cart,
  };
};

export const dispatchCartCustomer = (cartCustomer) => {
  localStorage.setItem(`cart_customer`, JSON.stringify(cartCustomer));
  return {
    type: actionTypes.SET_CART_CUSTOMER,
    cartCustomer: cartCustomer,
  };
};

export const setCart = (cart) => {
  return (dispatch) => {
    dispatch(dispatchCart(cart));
  };
};

export const modifyCart = (changedQuantity, newItem, isPlus, isMinus) => {
  const storedCartItems = JSON.parse(localStorage.getItem(`cart`));

  var cartCopy = [];
  if (storedCartItems) {
    cartCopy = [...storedCartItems];
    const itemIndex = findIndexForCartItem(cartCopy, newItem.id);
    if (itemIndex > -1) {
      const singleItem = cartCopy[itemIndex];

      var newQuantity = changedQuantity;
      if (isPlus) {
        newQuantity = singleItem.quantity + 1;
      }

      if (isMinus) {
        newQuantity = singleItem.quantity - 1;
      }

      const singleItemBeingEdited = {
        ...singleItem,
        quantity: newQuantity,
      };
      var payload = {
        index: itemIndex,
        item: singleItemBeingEdited,
      };
      cartCopy = updateObjectInArray(cartCopy, payload);
    } else {
      cartCopy = [...cartCopy, newItem];
    }
  } else {
    cartCopy = [newItem];
  }

  return (dispatch) => {
    dispatch(dispatchCart(cartCopy));
  };
};

export const autchCheckCart = () => {
  return (dispatch) => {
    const storedCartItems = JSON.parse(localStorage.getItem(`cart`));
    dispatch(dispatchCart(storedCartItems));
  };
};

export const authCheckCartCustomer = () => {
  return (dispatch) => {
    const cartCustomer = JSON.parse(localStorage.getItem(`cart_customer`));
    dispatch(dispatchCartCustomer(cartCustomer));
  };
};

export const setCartCustomer = (customer) => {
  return (dispatch) => {
    dispatch(dispatchCartCustomer(customer));
  };
};
